import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgentProjectTransactionModel } from '@shared/data-access/models';
import { filter, Observable, take, tap } from 'rxjs';
import { AgentProjectTransactionAction } from '../actions';
import { AgentProjectTransactionReducer } from '../reducers';
import { AgentProjectTransactionSelector } from '../selectors';

@Injectable()
export class AgentProjectTransactionFacade {
  selectedItem$ = this._store.select(AgentProjectTransactionSelector.selectProjectTransaction);
  constructor(private _store: Store<AgentProjectTransactionReducer.AgentProjectTransactionState>) { }

  /**
   * Get @AgentProjectTransactionModel by id
   */
  getById(id: number): Observable<AgentProjectTransactionModel | null> {
    this._store.dispatch(AgentProjectTransactionAction.loadItem({ id }));

    return this.selectedItem$.pipe(
      filter(val => !!val && val.transaction && val.transaction.id === id),
      take(1)
    ) as Observable<AgentProjectTransactionModel | null>;
  }
}
