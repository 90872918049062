import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectCommissionMatrixCreateDto, ProjectCommissionMatrixUpdateDto, ProjectCommissionStatusUpdateDto } from '@shared/data-access/dto';
import { ProjectCommissionMatrixModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { CommissionMatrixAction } from '../actions';
import { CommissionMatrixReducer } from '../reducers';
import { CommissionMatrixSelector } from '../selectors';

@Injectable()
export class CommissionMatrixFacade {
  agentInfo$ = this._store.select(CommissionMatrixSelector.selectCommissionAgentInfo);
  selectedItem$ = this._store.select(CommissionMatrixSelector.selectCommissionMatrix);
  newItem$ = this._store.select(CommissionMatrixSelector.selectNewCommissionMatrix);
  updatedItem$ = this._store.select(CommissionMatrixSelector.selectUpdatedCommissionMatrix);
  error$ = this._store.select(CommissionMatrixSelector.selectError);
  constructor(private _store: Store<CommissionMatrixReducer.CommissionMatrixState>) {}

  /**
   * Get @ProjectCommissionMatrixModel by id
   */
  getById(id: number): Observable<ProjectCommissionMatrixModel | null> {
    this._store.dispatch(CommissionMatrixAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<ProjectCommissionMatrixModel | null>;
  }

  getAgentInfo(id: number): void {
    this._store.dispatch(CommissionMatrixAction.getAgentInfo({ id }));
  }

  resetAgentInfo(): void {
    this._store.dispatch(CommissionMatrixAction.resetAgentInfo());
  }

  create(data: ProjectCommissionMatrixCreateDto): void {
    this._store.dispatch(CommissionMatrixAction.createItem({ data }));
  }

  update(id: number, data: ProjectCommissionMatrixUpdateDto): void {
    this._store.dispatch(CommissionMatrixAction.updateItem({ id, data }));
  }

  submit(payload: ProjectCommissionStatusUpdateDto): void {
    this._store.dispatch(CommissionMatrixAction.submitItem({ payload }));
  }

  createAndSubmit(data: ProjectCommissionMatrixCreateDto): void {
    this._store.dispatch(CommissionMatrixAction.createAndSubmitItem({ data }));
  }

  updateAndSubmitItem(id: number, updateData: ProjectCommissionMatrixUpdateDto, submitData: ProjectCommissionStatusUpdateDto): void {
    this._store.dispatch(CommissionMatrixAction.updateAndSubmitItem({ id, updateData, submitData }));
  }
}
