import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { PhoneNumberAction } from '../actions';
import { Action } from '@ngrx/store';
import { MetadataApiService } from '@agent-portal/apis';

@Injectable()
export class PhoneNumberEffects implements OnInitEffects {
  countries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhoneNumberAction.onCountriesLoading),
      mergeMap(() => this.metadataService.getCountryPhoneNumber().pipe(map(data => PhoneNumberAction.countriesLoaded({ payload: data }))))
    );
  });

  constructor(private actions$: Actions, private metadataService: MetadataApiService) {}

  ngrxOnInitEffects(): Action {
    return PhoneNumberAction.onCountriesLoading();
  }
}
