import { APP_CONFIG, IAppConfig } from '@agent-portal/config';
import { ROUTE } from '@agent-portal/constants';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { Confirmable } from '@shared/components/confirm-dialog';
import { LayoutBaseComponent } from '@shared/components/layout';
import { RedocConnectService } from '@shared/components/redoc-connect';
import { AuthProvider, AuthUtils, OauthService } from '@shared/features/auth';
import { Subject, Subscription, filter, of, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'ap-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent extends LayoutBaseComponent implements OnDestroy {
  private _authService = inject(AuthProvider);
  private _ssoService = inject(RedocConnectService);
  private _oauthService = inject(OauthService);
  private _appConfig: IAppConfig = inject(APP_CONFIG);
  private _unsubscribeAll$ = new Subject<void>();
  tokenReceivedSubscription?: Subscription;

  linkToHip = this._appConfig.hipUrl

  /**
   * Sign out
   */
  signOut(): void {
    console.log('hello sign out');
    this._router.navigate([ROUTE.AUTH.LOGOUT]);
    // console.log('hello sign out');
    // this._oauthService.logOut();
  }

  profile(): void {
    this._router.navigate([ROUTE.PERSONAL_INFO.PATH]);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.unsubscribe();
    this.tokenReceivedSubscription?.unsubscribe();
  }

  loginToRedoc() {
    this._authService
      .check()
      .pipe(
        switchMap(authenticated => {
          if (authenticated) {
            const accessToken = this._authService.accessToken;
            if (AuthUtils.isTokenExpired(accessToken)) {
              return this._authService.signInUsingRefreshToken();
            }
            return of(true);
          }
          return of(false);
        }),
        tap(authenticated => {
          console.log('authenticated', { authenticated, accesstoken: this._authService.accessToken });
          // If the user is not authenticated...
          if (authenticated) {
            const accessToken = this._authService.accessToken;
            if (this.tokenReceivedSubscription) {
              this.tokenReceivedSubscription.unsubscribe();
            }

            if (!('open' in window)) {
              this._ssoService.loginWithRedirect(this._appConfig.redocRedirectUri, { organization: 'hutton', with_token: accessToken })
              return
            }

            this._ssoService.loginWithPopup({
              with_token: accessToken,
              organization: 'hutton',
            });
            // this._ssoService.loginWithRedirect();
            this.tokenReceivedSubscription = this._ssoService.events
              .pipe(
                filter(e => e.type === 'token_received'),
                takeUntil(this._unsubscribeAll$)
              )
              .subscribe(() => {
                this.redirectToRedoc(this._appConfig.redocRedirectUri);
              });
          } else {
            // Both accessToken and refreshToken are expired
            this.signOut();
          }
        })
      )
      .subscribe();
  }
  @Confirmable((redocRedirectUri) => {
    return {
      type: 'success',
      title: 'Redoc is Logged in',
      description: 'Are you sure to redirect to Redoc?',
      confirmLabel: {
        text: 'OK',
        link: redocRedirectUri
      }
    }
  })
  redirectToRedoc(redocRedirectUri: string): void {
    // const redirectURL = redocRedirectUri;
    // window.open(redirectURL, '_blank');
  }
}
