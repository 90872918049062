import { BaseModel, Default, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ECreditNoteStatus, EFinanceCalculatingOrigin } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier, EGstCategoryCustomer } from '../enums/tax-invoice-enhanced.enum';
import { AddressDto } from './address.dto';

export class CreditNoteRecordDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount?: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Default(false)
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  accountId?: number;

  @Expose()
  @Type(() => String)
  accountCode?: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  remarks?: string;

  // @Expose()
  // bankReferralFee?: boolean;

  @Expose()
  description?: string;

  @Expose()
  uom?: string;

  @Expose()
  @Type(() => Number)
  creditNoteId?: number;

  @Expose()
  @Type(() => Number)
  taxInvoiceDetailId?: number;

  @Expose()
  @Type(() => Number)
  taxInvoiceId?: number;

  // @Expose()
  // @Type(() => Number)
  // agentId?: number;
}

export class CreditNotePostingDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  debit?: number;

  @Expose()
  @Type(() => Number)
  credit?: number;

  @Expose()
  @Type(() => Number)
  creditNoteId?: number;

  @Expose()
  @Type(() => Number)
  creditNoteDetailId?: number;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class CreditNoteDto extends BaseModel {
  //  ====== General ======
  @Expose()
  @Default(ECustomerOrSupplier.CUSTOMER)
  type!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId?: number;

  @Expose()
  @Type(() => Number)
  contactPersonId?: number;

  @Expose()
  @Type(() => Number)
  contactAddressId?: number;

  @Expose()
  creditNoteNumber?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  creditNoteDate!: string;

  // @Expose()
  // @Default('SGD')
  // @Transform(({ value }) => 'SGD')
  // currency!: string;

  @Expose()
  @Type(() => Number)
  currencyId!: number;

  @Expose()
  status!: ECreditNoteStatus;

  @Expose()
  @Type(() => Number)
  taxInvoiceId?: number;

  @Expose()
  @Type(() => Boolean)
  isWithHold?: boolean;

  @Expose()
  creditNoteRemarks?: string;

  //  ====== Other detail ======
  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  // @Type(() => Number)
  // @Default(0)
  // paymentTerm?: number;
  paymentTerm?: string;

  //   @Expose()
  //   printFormat?: string;

  @Expose()
  @Type(() => Number)
  salePersonId?: number;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  @Type(() => AddressDto)
  billingAddress!: AddressDto;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  @Expose()
  @Type(() => Number)
  templateId?: number;

  //  ====== Calculate data =====
  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  // @Expose()
  // @Type(() => Number)
  // taxValue?: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  gstEdited?: boolean;

  @Expose()
  @Type(() => CreditNoteRecordDto)
  details!: CreditNoteRecordDto[];

  @Expose()
  @Type(() => CreditNotePostingDto)
  postings!: CreditNotePostingDto[];
}

export class CreditNotePostingReqItemDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => String)
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  amount?: number;
}

export class CreditNotePostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Default(ECustomerOrSupplier.CUSTOMER)
  type!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId!: number | null;

  @Expose()
  @Type(() => Number)
  taxInvoiceId!: number | null;

  @Expose()
  @Type(() => CreditNotePostingReqItemDto)
  items!: CreditNotePostingReqItemDto[];
}

export class CreditNoteCalculateSummaryReqDto extends BaseModel {
  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  gstValue!: number;

  // @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  // @Type(() => Number)
  // amount!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => CreditNoteRecordDto)
  details!: CreditNoteRecordDto[];

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => String)
  balanceDocumentType!: string
}

// Pdf Preview
export class CreditNotePreviewInvoicePdfDetailDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  taxInvoiceId?: number;

  @Expose()
  taxInvoiceDetailId?: number;

  @Expose()
  description!: string;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  itemCode?: string;

  @Expose()
  gstValue?: number;

  @Expose()
  discount?: number;

  @Expose()
  amount!: number;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  remarks?: string;
}

export class CreditNoteLinkedInvoicePreviewInvoicePdfDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  date?: string;

  @Expose()
  number?: string;
}

export class CreditNotePreviewInvoicePdfDto extends BaseModel {
  @Expose()
  id!: number

  @Expose()
  businessUnitId!: number

  @Expose()
  @Default(5)
  @Type(() => Number)
  templateId?: number;

  @Expose()
  customerName?: string;

  @Expose()
  billingAddress?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  creditNoteDate?: string;

  @Expose()
  creditNoteNumber?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  reference?: string;

  @Expose()
  gstEdited?: boolean;

  // @Expose()
  // @Type(() => Number)
  // gstValue?: number;

  @Expose()
  @Type(() => Number)
  taxValue?: number;

  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent?: number;

  @Expose()
  currency?: string;

  @Expose()
  contactPerson?: string;

  @Expose()
  contactCode?: string;

  @Expose()
  salePerson?: string;

  @Expose()
  remark?: string;

  @Expose()
  creditNoteRemarks?: string;

  // @Expose()
  // @Type(() => CreditNoteLinkedInvoicePreviewInvoicePdfDto)
  // invoice?: CreditNoteLinkedInvoicePreviewInvoicePdfDto;

  @Expose()
  @Type(() => CreditNotePreviewInvoicePdfDetailDto)
  details?: CreditNotePreviewInvoicePdfDetailDto[];
}
