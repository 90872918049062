import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommissionMatrixReducer } from '../reducers';

export const selectCommissionMatrixFeature = createFeatureSelector<CommissionMatrixReducer.CommissionMatrixState>(CommissionMatrixReducer.featureName);

export const selectCommissionMatrix = createSelector(selectCommissionMatrixFeature, (state: CommissionMatrixReducer.CommissionMatrixState) => state.item);
export const selectNewCommissionMatrix = createSelector(selectCommissionMatrixFeature, (state: CommissionMatrixReducer.CommissionMatrixState) => state.newItem);
export const selectCommissionAgentInfo = createSelector(selectCommissionMatrixFeature, (state: CommissionMatrixReducer.CommissionMatrixState) => state.agentInfo);
export const selectUpdatedCommissionMatrix = createSelector(
  selectCommissionMatrixFeature,
  (state: CommissionMatrixReducer.CommissionMatrixState) => state.updatedItem
);

export const selectError = createSelector(selectCommissionMatrixFeature, (state: CommissionMatrixReducer.CommissionMatrixState) => state.error);
