// core
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

// share
import { PersonalParticularAddressModel, RecruitmentApplicationPersonalModel } from '@shared/data-access/models';
import { RecruitmentApplicationSelector } from '../selectors';
import { RecruitmentApplicationReducer } from '../reducers';
import { RecruitmentApplicationAction } from '../actions';

@Injectable()
export class RecruitmentApplicationFacade {
  application$ = this._store.select(RecruitmentApplicationSelector.selectDetail);
  recruitment$ = this._store.select(RecruitmentApplicationSelector.selectRecruitment);
  personal$ = this._store.select(RecruitmentApplicationSelector.selectPersonal);
  address$ = this._store.select(RecruitmentApplicationSelector.selectAddress);
  portal$ = this._store.select(RecruitmentApplicationSelector.selectPostal);
  templates$ = this._store.select(RecruitmentApplicationSelector.selectTemplates);
  state$ = this._store.select(RecruitmentApplicationSelector.selectState);

  constructor(private _store: Store<RecruitmentApplicationReducer.RecruitmentApplicationState>) {}

  getRecruitmentById(id: number): void {
    this._store.dispatch(RecruitmentApplicationAction.loadRecruitment({ id }));
  }

  getTemplateById(ids: number[]): void {
    this._store.dispatch(RecruitmentApplicationAction.loadTemplate({ ids }));
  }

  updatePersonal(personal: RecruitmentApplicationPersonalModel): void {
    this._store.dispatch(RecruitmentApplicationAction.updatePersonal({ personal }));
  }

  updateAddress(address: PersonalParticularAddressModel): void {
    this._store.dispatch(RecruitmentApplicationAction.updateAddress({ address }));
  }

  updatePostal(postal: string): void {
    this._store.dispatch(RecruitmentApplicationAction.updatePostal({ postal }));
  }
}
