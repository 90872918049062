import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { PreviewOriginalTemplateDto } from '@shared/data-access/dto';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PreviewApiService {
  static ROOT_POINT = 'finance';
  constructor(private _apiService: ApiService) { }

  viewOriginal(data: PreviewOriginalTemplateDto): Observable<any> {
    return this._apiService.post(PreviewApiService.ROOT_POINT + '/template-v2/preview/original', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  viewPreview(data: PreviewOriginalTemplateDto): Observable<any> {
    return this._apiService.post(PreviewApiService.ROOT_POINT + '/template-v2/preview', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  getTemplates(templateId: number): Observable<any> {
    return this._apiService.get(PreviewApiService.ROOT_POINT + '/templates/' + templateId)
  }

}

export class PreviewTransactionApiService {
  static ROOT_POINT = 'transaction';
  constructor(private _apiService: ApiService) { }

  viewOriginal(data: PreviewOriginalTemplateDto): Observable<unknown> {
    return this._apiService.post(PreviewApiService.ROOT_POINT + '/template-v2/preview/original', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  viewPreview(data: PreviewOriginalTemplateDto): Observable<unknown> {
    return this._apiService.post(PreviewApiService.ROOT_POINT + '/template-v2/preview', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
