import { createFeature, createReducer, on } from '@ngrx/store';
import { ProjectModel } from '@shared/data-access/models';
import { ProjectSetupAction } from '../actions';

export const featureName = 'projectSetup';

export interface ProjectSetupState {
  item: ProjectModel | null;
}
export const initialState: ProjectSetupState = {
  item: null,
};
export const projectSetupFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProjectSetupAction.loadItemSuccess, (state, prop: { item: ProjectModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = projectSetupFeature;
