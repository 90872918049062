import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Helper } from '@red/utils';
import { EClientType } from '@shared/data-access/enums';
import { ResaleTransactionReducer } from '../reducers';

export const selectResaleTransactionFeature = createFeatureSelector<ResaleTransactionReducer.ResaleTransactionState>(ResaleTransactionReducer.featureName);

export const selectItemDetail = createSelector(selectResaleTransactionFeature, ({ item }) => item);

export const selectResaleTransactionId = createSelector(selectResaleTransactionFeature, ({ item }) => item?.id);

export const selectDraftItem = createSelector(selectResaleTransactionFeature, ({ draftItem }) => draftItem);

export const selectDraftType = createSelector(selectResaleTransactionFeature, ({ draftType }) => draftType);

export const selectDraftState = createSelector(selectResaleTransactionFeature, ({ draftItem, draftType }) => ({
  draftItem,
  draftType,
}));

export const selectSubmittingStatus = createSelector(selectResaleTransactionFeature, ({ submittingStatus }) => submittingStatus);

export const selectTransactionAmountDraftItem = createSelector(selectDraftItem, ({ billing }) => billing?.transactionAmount as number | null);

export const selectTotalExternalsReceivedPercentDraftItem = createSelector(selectDraftItem, ({ relatedAgent }) =>
  relatedAgent.externals.reduce((total, item) => Helper.round(total + (item?.receivedPercent ?? 0), 4), 0)
);

export const selectBillingValueDraftItem = createSelector(selectDraftItem, ({ billing }) => billing?.grossComm?.subTotalAmount ?? 0);

export const selectedBillingGstPercent = createSelector(selectDraftItem, ({ billing }) => billing?.gstPercent ?? 0);

export const selectPropertyAddressDraftItem = createSelector(selectDraftItem, ({ propertyAddress }) => propertyAddress);

export const selectCountryDraftItem = createSelector(selectDraftItem, ({ country }) => country);

export const selectPropertyAddressFullDraftItem = createSelector(selectPropertyAddressDraftItem, selectCountryDraftItem, (propertyAddress, country) => ({
  ...propertyAddress,
  country,
}));

export const selectResaleTypeDraftItem = createSelector(selectDraftItem, ({ resaleType }) => resaleType);

export const selectBillingMetadataDraftItem = createSelector(selectDraftItem, ({ billing }) => billing?.metadata);

export const selectIsCobrokeDraftItem = createSelector(selectDraftItem, ({ metadata }) => !!metadata?.isCobroke);

export const selectIsWithPaymentDraftItem = createSelector(selectDraftItem, ({ metadata }) => !!metadata?.isWithPayment);

export const selectSubAmountDraftItem = createSelector(selectDraftItem, ({ billing }) => billing?.subAmount ?? null);

export const selectSubTotalAmountDraftItem = createSelector(selectDraftItem, ({ billing }) => billing?.grossComm?.subTotalAmount ?? null);

export const selectDocumentsDraftItem = createSelector(selectDraftItem, ({ documents }) => documents);

export const selectClientType = createSelector(selectDraftItem, ({ billing }) => billing.clientType ?? EClientType.seller);

export const selectPropertyTypeDraftItem = createSelector(selectDraftItem, ({ propertyType }) => propertyType);

export const selectPaymentAdviceDraftItem = createSelector(selectDraftItem, ({ billing }) => billing.paymentAdvice);

export const selectBillingDraftItem = createSelector(selectDraftItem, ({ billing }) => billing);

export const selectRelatedAgentDraftItem = createSelector(selectDraftItem, ({ relatedAgent }) => relatedAgent);

export const selectBillingContactDraftItem = createSelector(selectBillingDraftItem, ({ contact }) => contact);

export const selectIsCloneDuplicated = createSelector(selectResaleTransactionFeature, ({ isCloneDuplicated }) => isCloneDuplicated);
