import { UserService } from '@agent-portal/user/services';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BroadcastChannelService } from '@shared/components/broadcast-channel';
import { SentryCoreModule } from '@shared/components/sentry';
import { AuthActionEvent, AuthProvider, OauthService } from '@shared/features/auth';
import { USER_LOADER, UserAdapter } from '@shared/features/user';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'ap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'agent-portal';
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(private _authService: AuthProvider, private _oauthService: OauthService, private _broadcast: BroadcastChannelService, @Inject(USER_LOADER) private _userService: UserService) { }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnInit(): void {
    this._oauthService.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(event => {
      console.log('event --> ', event);
    });
    this._authService.actionEvent.pipe(takeUntil(this._unsubscribeAll)).subscribe(event => {
      this._broadcast.dispatch<AuthActionEvent>({
        channelName: 'appState',
        type: 'authAction',
        payload: event,
      });
    });

    this._broadcast
      .on<AuthActionEvent>('appState', 'authAction')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        location.reload();
      });

    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
      const payload = user ? {
        userId: user.id,
        name: user.name,
        email: user.username
      } : null;
      SentryCoreModule.setUser(payload)
    })
  }
}
