import { Injectable } from '@angular/core';
import { KeyAppointmentApiService } from '@agent-portal/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { KeyAppointmentAction } from '../actions';

@Injectable()
export class KeyAppointmentEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(KeyAppointmentAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => KeyAppointmentAction.loadItemSuccess({ item })));
      })
    );
  });
  loadMainIc$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(KeyAppointmentAction.loadMainIc),
      exhaustMap(action => {
        return this._apiService.getMainIcByProjectId(action.projectId).pipe(map(item => KeyAppointmentAction.loadItemSuccess({ item })));
      })
    );
  });
  constructor(private _apiService: KeyAppointmentApiService, private _actions$: Actions, private _store: Store) {}
}
