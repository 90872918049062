export interface IIngeniqueApiConfig {
  token: string;
  apiKey: string;
  sectorId: string;
  accountName: string;
}

export interface IIngeniqueApiSearchAmlQuery {
  country: string | null;
  fuzzy: boolean;
  name: string;
  searchEntity: boolean;
  searchIndividual: boolean;
  gender: 'Male' | 'Female' | null;
  dateOfBirth: string;
}

export interface IIngeniqueApiSearchAmlResponse {
  amlReportId: string;
  monitorEnabled: boolean;
  name: string;
  country: string;
  searchEntity: boolean;
  searchIndividual: boolean;
  fuzzyValue: boolean;
  orderType: string;
  fatfCountries: any[];
  pepPersons: IAmlClient[];
  sipPersons: IAmlClient[];
  sieEntities: IAmlClient[];
}

export interface IAmlClient {
  id: number;
  action: string;
  date: string;
  gender: string;
  activeStatus: string;
  deceased: string;
  birthplace: string;
  profileNotes: string;
  nameDetails: INameDetail[];
  dateDetails: IDateDetail[];
  countryDetails: ICountryDetail[];
  sanctionsReferences: ISanctionsReferences[];
  roleDetail: IRoleDetail[];
  address: IAddressDetail[];
  descriptions: IDescription[];
}

export interface INameDetail {
  nameId: number;
  nameType: string;
  entityName: string;
  originalScriptName: string;
  titleHonorific: string;
  firstName: string;
  middleName: string;
  surname: string;
  maidenName: string;
  suffix: string;
  singleStringName: string;
}

export interface IDateDetail {
  dateId: number;
  dateType: string;
  dateValue: string;
  dateNotes: string;
}

export interface ICountryDetail {
  countryLinkId: number;
  countryType: string;
  country: {
    countryId: number;
    countryCode: string;
    countryName: string;
    isTerritory: string;
    profileUrl: string;
  };
}

export interface ISanctionsReferences {
  sanctionReferenceId: number;
  sinceDate: string;
  toDate: string;
  sanction?: {
    sanctionCode: string;
    sanctionName: string;
    sanctionStatus: string;
  };
}

export interface IRoleDetail {
  roleId: number;
  roleType: string;
  sinceDate: string;
  toDate: string;
  occCat: {
    code: string;
    occupationName: string;
  };
  occupationTitle: string;
}

export interface IAddressDetail {
  addressId: number;
  addressLine: string;
  addressCity: string;
  addressCountry: string;
  addressURL: string;
}

export interface IDescription {
  descriptionId: number;
  description1: {
    description1Id: string;
    description1Name: string;
    recordType: string;
  };
  description2: string;
  description3: string;
}
