import { createFeature, createReducer, on } from '@ngrx/store';
import { EResalePropertyType, EResaleStatus } from '@shared/data-access/enums';
import { AddressModel, ResaleBillingModel, ResaleRelatedAgentModel, ResaleTransactionModel } from '@shared/data-access/models';
import { isEqual } from 'lodash-es';
import { ResaleTransactionAction } from '../actions';
import { RESALE_PROPERTY_TYPE_OPTIONS } from '@shared/data-access/data';

export const featureName = 'resaleTransaction';

export interface ResaleTransactionState {
  item: ResaleTransactionModel | null;
  draftItem: ResaleTransactionModel;
  draftType: 'create' | 'edit' | 'clone';
  submittingStatus: {
    success: any;
    loading: boolean;
    error: any;
  };
  previewStatus: {
    success: any;
    loading: boolean;
    error: any;
  };
  isCloneDuplicated: boolean;
}

export const initialState: ResaleTransactionState = {
  item: null,
  draftItem: ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
  draftType: 'create',
  submittingStatus: {
    success: null,
    loading: false,
    error: null,
  },
  previewStatus: {
    success: null,
    loading: false,
    error: null,
  },
  isCloneDuplicated: false,
};

export const resaleTransactionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ResaleTransactionAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
    on(ResaleTransactionAction.updateItemDetail, (state, { data }) => ({
      ...state,
      item: state.item ? ResaleTransactionModel.merge(state.item, data) : null,
    })),
    on(ResaleTransactionAction.setDraftItemAsCreate, state => ({
      ...state,
      draftItem: ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      draftType: 'create',
    })),
    on(ResaleTransactionAction.setDraftItemAsEdit, state => ({
      ...state,
      draftItem: state.item
        ? ResaleTransactionModel.clone(
          ResaleTransactionModel.merge(state.item, {
            relatedAgent: {
              ...state.item.relatedAgent,
              mains: state.item.relatedAgent.mains.map(item => ({
                ...item,
                brokerName: item.brokerName || item.agent?.nricName,
                brokerIdNo: item.brokerIdNo || item.agent?.ceaRegNo,
              })),
            },
          }),
          { groups: ['draft'] }
        )
        : ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      draftType: 'edit',
    })),
    on(ResaleTransactionAction.setDraftItemAsClone, state => ({
      ...state,
      draftItem: state.item
        ? ResaleTransactionModel.clone(
          ResaleTransactionModel.fromJson({
            ...state.item,
            propertyType: (RESALE_PROPERTY_TYPE_OPTIONS[state.item?.propertyType as EResalePropertyType]?.['hidden'] ? undefined : state.item?.propertyType),
            status: EResaleStatus.draft,
            relatedAgent: {
              mains: [],
              internals: [],
              externals: [],
            },
            documents: [],
            completionDate: null,
            billing: {
              ...state.item.billing,
              billingDate: null,
              ecddIds: null,
              ecdds: null,
              ecddRef: null,
              // transactionAmount: null,
              // percentage: null,
              grossComm: {
                amount: null,
                subTotalAmount: null,
                taxAmount: null,
                totalAmount: null,
              },
              attention: null,
              commission: {
                type: 'number',
                value: 0,
              },
              chequeDate: null,
              paymentMethod: null,
              metadata: {
                ...state.item.metadata,
                isCobroke: false,
                isWithPayment: false,
              },
            },
            remarks: null,
            remarksInternal: null,
            metadata: {},
          }),
          { groups: ['draft'] }
        )
        : ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      draftType: 'clone',
    })),
    on(ResaleTransactionAction.updateDraftItem, (state, { data }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, data, { groups: ['draft'] }),
    })),
    on(ResaleTransactionAction.updateResaleTypeDraftItem, (state, { resaleType }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, { resaleType }, { groups: ['draft'] }),
    })),
    on(ResaleTransactionAction.updateTransactionAmountDraftItem, (state, { transactionAmount }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(
        state.draftItem,
        { billing: ResaleBillingModel.merge(state.draftItem.billing, { transactionAmount }) },
        { groups: ['draft'] }
      ),
    })),
    on(ResaleTransactionAction.updateIsCobrokeDraftItem, (state, { isCobroke }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(
        state.draftItem,
        { billing: ResaleBillingModel.merge(state.draftItem.billing, { metadata: { ...state.draftItem.billing.metadata, isCobroke } }) },
        { groups: ['draft'] }
      ),
    })),
    on(ResaleTransactionAction.updateBillingDraftItem, (state, { data }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(
        state.draftItem,
        {
          billing: ResaleBillingModel.merge(state.draftItem.billing, data),
        },
        { groups: ['draft'] }
      ),
    })),
    on(ResaleTransactionAction.addExternalDraftItem, (state, { item }) => {
      const externals = [item, ...state.draftItem.relatedAgent.externals];
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              externals,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.updateExternalDraftItem, (state, { index, item }) => {
      const externals = [...state.draftItem.relatedAgent.externals];
      externals[index] = item;
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              externals,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.deleteExternalDraftItem, (state, { index }) => {
      const externals = [...state.draftItem.relatedAgent.externals];
      externals.splice(index, 1);
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              externals,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.addDocumentsDraftItem, (state, { items }) => {
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            documents: [...state.draftItem.documents, ...items],
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.deleteDocumentDraftItem, (state, { items }) => {
      const documents = [...state.draftItem.documents];
      items.forEach(item => {
        const index = documents.findIndex(i => i.url === item.url);
        if (index > -1) {
          documents.splice(index, 1);
        }
      });
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            documents,
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.saveDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.submitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.submitItemSuccess, (state, { res }) => ({
      ...state,
      submittingStatus: {
        success: res,
        loading: false,
        error: null,
      },
    })),
    on(ResaleTransactionAction.submitItemFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error,
      },
    })),
    on(ResaleTransactionAction.resetDraftItem, state => ({
      ...state,
      item: null,
      draftItem: ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      draftType: 'create',
    })),
    on(ResaleTransactionAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error: null,
      },
    })),
    on(ResaleTransactionAction.updateBillingMetadataDraftItem, (state, { data }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, {
        billing: ResaleBillingModel.merge(state.draftItem.billing, {
          metadata: { ...state.draftItem.billing.metadata, ...data },
        }),
      }),
    })),
    on(ResaleTransactionAction.updatePropertyAddressDraftItem, (state, { address }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, {
        propertyAddress: AddressModel.merge(state.draftItem.propertyAddress ?? AddressModel.createEmpty(), address),
      }),
    })),
    on(ResaleTransactionAction.updateBillingAddressDraftItem, (state, { address }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, {
        billing: ResaleBillingModel.merge(state.draftItem.billing, {
          billingAddress: AddressModel.merge(state.draftItem.billing.billingAddress ?? AddressModel.createEmpty(), address),
        }),
      }),
    })),
    on(ResaleTransactionAction.updateMetadataDraftItem, (state, { metadata }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, {
        metadata: { ...state.draftItem.metadata, ...metadata },
      }),
    })),
    on(ResaleTransactionAction.checkDuplicateDraftItem, state => {
      const { item, draftItem } = state;
      const objectItem = {
        clientName: item?.billing?.clientName,
        propertyAddress: item?.propertyAddress?.address,
        closingAgentId: item?.relatedAgent?.mains?.[0]?.agentId,
        optionDate: item?.optionDate ? new Date(item?.optionDate).getTime() : null,
      };
      const comparedItem = {
        clientName: draftItem.billing.clientName,
        propertyAddress: draftItem.propertyAddress?.address,
        closingAgentId: draftItem.relatedAgent?.mains?.[0]?.agentId,
        optionDate: draftItem.optionDate ? new Date(draftItem.optionDate).getTime() : null,
      };
      const isCloneDuplicated = isEqual(objectItem, comparedItem);
      return { ...state, isCloneDuplicated };
    }),
    on(ResaleTransactionAction.loadGrossCommPreviewFail, (state, { error }) => ({
      ...state,
      previewStatus: {
        success: null,
        loading: false,
        error,
      },
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = resaleTransactionFeature;
