import { createFeature, createReducer, on } from '@ngrx/store';
import { BlankFormAction } from '../actions';
import { BlankFormModel } from '@shared/data-access/models/blank-form.model';
import {
  BlankFormBdModel,
  BlankFormExternalCoBrokeModel,
  BlankFormIcModel,
  BlankFormInternalCoBrokeModel,
  BlankFormOtherFeeModel,
  BlankFormPdModel,
  BlankFormReferralModel,
  BlankFormRelatedAgentModel,
  ProjectTransactionBonusModel,
  ProjectTransactionRelatedPartyModel,
  SplitMatrixModel,
  SplitPartModel,
} from '@shared/data-access/models';
import { IBlankFormBd, IBlankFormRelatedAgent } from '@shared/data-access/interfaces';

export const featureName = 'blankForm';

export interface BlankFormState {
  item: BlankFormModel | null;
  draftItem: BlankFormModel;
  draftType: 'create' | 'edit';
  submittingStatus: {
    success: any;
    loading: boolean;
    error: any;
  };
}

export const initialState: BlankFormState = {
  item: null,
  draftItem: BlankFormModel.createEmpty(),
  draftType: 'create',
  submittingStatus: {
    success: null,
    loading: false,
    error: null,
  },
};

export const blankFormFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BlankFormAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
    on(BlankFormAction.updateItemDetail, (state, { data }) => ({
      ...state,
      item: state.item ? BlankFormModel.merge(state.item, data) : null,
    })),
    on(BlankFormAction.setDraftItemAsCreate, state => ({
      ...state,
      draftItem: BlankFormModel.createEmpty(),
      draftType: 'create',
    })),
    on(BlankFormAction.setDraftItemAsEdit, state => ({
      ...state,
      draftItem: state.item ? BlankFormModel.clone(state.item) : BlankFormModel.createEmpty(),
      draftType: 'edit',
    })),
    on(BlankFormAction.updateDraftItem, (state, { data }) => ({
      ...state,
      draftItem: BlankFormModel.merge(state.draftItem, data),
    })),
    on(BlankFormAction.setDefaultValueRelatedAgentFromReference, (state, { relatedAgent }) => {
      let relatedAgentModel: BlankFormRelatedAgentModel;
      let relatedAgentPlain: IBlankFormRelatedAgent;
      if (relatedAgent instanceof ProjectTransactionRelatedPartyModel) {
        relatedAgentPlain = ProjectTransactionRelatedPartyModel.toJson(relatedAgent) as IBlankFormRelatedAgent;
      } else {
        relatedAgentPlain = BlankFormRelatedAgentModel.toJson(relatedAgent) as IBlankFormRelatedAgent;
      }
      relatedAgentModel = BlankFormRelatedAgentModel.fromJson(relatedAgentPlain);
      const { mains, internals, externals, leaders } = relatedAgentModel;
      const [mainsDefault, internalsDefault, externalsDefault, leadersDefault] = [mains, internals, externals, leaders].map(agents =>
        agents.map(agent => {
          if (!(agent instanceof BlankFormExternalCoBrokeModel)) {
            const { tiers: tierAgent, parties: partiesAgent } = agent;
            const tiers = tierAgent.map(tier =>
              SplitPartModel.merge(tier, {
                defaultValue: tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.value,
                value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
              })
            );
            const parties = partiesAgent.map(party =>
              SplitPartModel.merge(party, {
                defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
                value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
              })
            );
            return BlankFormInternalCoBrokeModel.merge(agent, { isDefault: true, tiers, parties });
          }
          return BlankFormExternalCoBrokeModel.merge(agent, { isDefault: true, receivedValue: 0 });
        })
      );
      const relatedAgentDefault = BlankFormRelatedAgentModel.merge(BlankFormRelatedAgentModel.createEmpty(), {
        mains: mainsDefault,
        internals: internalsDefault,
        externals: externalsDefault,
        leaders: leadersDefault,
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          relatedAgent: relatedAgentDefault,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValueBonusesFromReference, (state, { bonuses }) => {
      const defaultBonuses = bonuses.map(bonus => {
        const { salespersonId, salesperson } = bonus;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = bonus;
        const bonusPlain = bonuses instanceof ProjectTransactionBonusModel ? ProjectTransactionBonusModel.toJson(bonus) : BlankFormIcModel.toJson(bonus);
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue:
              tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.value,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue:
              party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormIcModel.fromJson({ ...bonusPlain, isDefault: true, tiers, parties });
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          bonuses: defaultBonuses,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValueOverridingsFromReference, (state, { overridings }) => {
      const defaultOverridings = overridings.map(overriding => {
        const { salespersonId, salesperson, appointmentType, appointmentTypeId } = overriding;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = overriding;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.value,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormIcModel.fromJson({
          isDefault: true,
          tiers,
          parties,
          salespersonId,
          salesperson,
          appointmentType,
          appointmentTypeId,
        });
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          overridings: defaultOverridings,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValuePoolsFromReference, (state, { pools }) => {
      const defaultPools = pools.map(pool => {
        const { salespersonId, salesperson, appointmentType, appointmentTypeId } = pool;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = pool;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.value,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormIcModel.fromJson({
          isDefault: true,
          tiers,
          parties,
          salespersonId,
          salesperson,
          appointmentType,
          appointmentTypeId,
        });
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          pools: defaultPools,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValuePdsFromReference, (state, { pds }) => {
      const defaultPds = pds.map(pd => {
        const { pdRole, salespersonId, salesperson, appointmentType, appointmentTypeId } = pd;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = pd;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.value,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormPdModel.fromJson({
          isDefault: true,
          pdRole,
          tiers,
          parties,
          salespersonId,
          salesperson,
          appointmentType,
          appointmentTypeId,
        });
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          pds: defaultPds,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValueReferralsFromReference, (state, { referrals }) => {
      const defaultReferrals = referrals.map(referral => {
        const { salespersonId, salesperson, agent, agentId, type, absorbType, formula } = referral;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = referral;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.value,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormReferralModel.fromJson({
          isDefault: true,
          tiers,
          parties,
          salespersonId,
          salesperson,
          agentId,
          agent,
          type,
          absorbType,
          formula,
          receivedValue: 0,
        });
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          referrals: defaultReferrals,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValueBdsFromReference, (state, { bds }) => {
      const defaultBds = bds.map(bd => {
        const { salesperson, salespersonId, appointmentType, appointmentTypeId, type } = bd;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = bd;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId ?? tier.salesperson?.id ? tier.value : null ?? tier.value,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormBdModel.fromJson({
          isDefault: true,
          salesperson,
          salespersonId,
          appointmentType,
          appointmentTypeId,
          type,
          tiers,
          parties,
        } as Partial<IBlankFormBd>);
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          bds: defaultBds,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValueOtherFeeFromReference, (state, { otherFees }) => {
      const defaultOtherFees = otherFees.map(otherFee => {
        const { code, name } = otherFee;
        return BlankFormOtherFeeModel.fromJson({
          isDefault: true,
          name,
          code,
          receivedValue: 0,
        });
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          otherFees: defaultOtherFees,
        }),
      };
    }),
    on(BlankFormAction.saveDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(BlankFormAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(BlankFormAction.submitItemSuccess, (state, { res }) => ({
      ...state,
      submittingStatus: {
        success: res,
        loading: false,
        error: null,
      },
    })),
    on(BlankFormAction.submitItemFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error,
      },
    })),
    on(BlankFormAction.resetDraftItem, state => ({
      ...state,
      item: null,
      draftItem: BlankFormModel.createEmpty(),
      draftType: 'create',
    })),
    on(BlankFormAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error: null,
      },
    })),
    on(BlankFormAction.setLoadingStatus, (state, { loading }) => {
      return ({
        ...state,
        submittingStatus: {
          success: null,
          loading,
          error: null,
        },
      })
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = blankFormFeature;
