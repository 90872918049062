import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { provideSentry } from '@shared/components/sentry';

if (environment.production) {
  enableProdMode();
}
// enable sentry
// provideSentry(environment.sentryConfig.options)
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
(window as any).webpackJsonp = (window as any).webpackJsonp || [];
(window as any).webpackJsonp.push = function (chunk: any) {
  try {
    // eslint-disable-next-line prefer-rest-params
    Array.prototype.push.apply(this, arguments as any);
  } catch (err: any) {
    if (err.message && err.message.indexOf('Loading chunk') !== -1) {
      // Here you can add your custom handling logic
      alert('An error occurred while loading the application. Please refresh the page.');
      window.location.reload();
    } else {
      console.error(err);
    }
  }
};