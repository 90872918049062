import { exhaustMap, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { PersonalProfileApiService } from '@agent-portal/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FileUploaderModel } from '@red/data-access';
import { PersonalProfileAction } from '../actions';

@Injectable()
export class PersonalProfileEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(PersonalProfileAction.loadParticular),
      exhaustMap(() => this._apiService.get().pipe(map(particular => PersonalProfileAction.loadParticularSuccess({ particular }))))
    );
  });

  loadDivision$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(PersonalProfileAction.loadDivision),
      exhaustMap(() => this._apiService.getHeadDivision().pipe(map(division => PersonalProfileAction.loadDivisionSuccess({ division }))))
    );
  });

  loadDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PersonalProfileAction.loadDocuments),
      exhaustMap(() =>
        this._apiService.getDocument().pipe(
          map(files => {
            const documents = files.map((file: unknown) => FileUploaderModel.fromJson(file));
            return PersonalProfileAction.loadDocumentsSuccess({ documents });
          })
        )
      )
    )
  );

  constructor(private _apiService: PersonalProfileApiService, private _actions$: Actions) {}
}
