import { Injectable } from '@angular/core';
import { AgentProjectTransactionApiService } from '@agent-portal/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { AgentProjectTransactionAction } from '../actions';

@Injectable()
export class AgentProjectTransactionEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AgentProjectTransactionAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => AgentProjectTransactionAction.loadItemSuccess({ item })));
      })
    );
  });
  constructor(private _apiService: AgentProjectTransactionApiService, private _actions$: Actions, private _store: Store) { }
}
