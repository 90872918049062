import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectTransactionReducer } from '../reducers';

export const selectProjectTransactionFeature = createFeatureSelector<ProjectTransactionReducer.ProjectTransactionState>(ProjectTransactionReducer.featureName);

export const selectProjectTransaction = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.ProjectTransactionState) => state.item);

export const selectCobrokeLeaders = createSelector(
  selectProjectTransactionFeature,
  (state: ProjectTransactionReducer.ProjectTransactionState) => state.cobrokeLeaders
);

export const selectCombinedLeaders = createSelector(
  selectProjectTransactionFeature,
  (state: ProjectTransactionReducer.ProjectTransactionState) => state.combinedLeaders
);

export const selectHasLeaders = createSelector(selectCombinedLeaders, leaders => leaders.length > 0);

export const selectAgreementDate = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.ProjectTransactionState) => state.agreementDate);

export const selectFeatureBds = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.ProjectTransactionState) => state.bds);
