import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { ProjectConsultantModel, ProjectTeamMemberModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[ProjectConsultant] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[ProjectConsultant] Load items success', props<{ data: PaginationAdapter<ProjectConsultantModel> }>());

// item
export const loadItem = createAction('[ProjectConsultant] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[ProjectConsultant] Load item success', props<{ item: ProjectConsultantModel }>());

export const countMember = createAction('[Project] COUNT MEMBER', props<{ teamId: number }>());
export const countMemberSuccess = createAction('[Project] COUNT MEMBER SUCCESS', props<{ teamId: number; total: number }>());
export const countMemberFailure = createAction('[Project] COUNT MEMBER FAILURE', props<{ error: any }>());

export const getLeader = createAction('[Project] GET LEADER', props<{ teamId: number }>());
export const getLeaderSuccess = createAction('[Project] GET LEADER SUCCESS', props<{ teamId: number; leader: ProjectTeamMemberModel }>());
export const getLeaderFailure = createAction('[Project] GET LEADER FAILURE', props<{ error: any }>());
