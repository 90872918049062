// core
import { createAction, props } from '@ngrx/store';

// share
import {
  RecruitmentApplicationPersonalModel,
  PersonalParticularAddressModel,
  RecruitmentApplicationModel,
  RecruitmentModel,
  TemplateModel,
} from '@shared/data-access/models';

// detail
export const loadDetail = createAction('[RecruitmentApplication] Load detail', props<{ id: number }>());
export const loadDetailSuccess = createAction('[RecruitmentApplication] Load detail success', props<{ detail: RecruitmentApplicationModel }>());

// recruitment
export const loadRecruitment = createAction('[RecruitmentApplication] Load recruitment', props<{ id: number }>());
export const loadRecruitmentSuccess = createAction('[RecruitmentApplication] Load recruitment success', props<{ recruitment: RecruitmentModel }>());
export const loadRecruitmentFailure = createAction('[RecruitmentApplication] Load recruitment failure', props<{ error: unknown }>());

// template
export const loadTemplate = createAction('[RecruitmentApplication] Load template', props<{ ids: number[] }>());
export const loadTemplateSuccess = createAction('[RecruitmentApplication] Load template success', props<{ templates: TemplateModel[] }>());

// personal
export const updatePersonal = createAction('[RecruitmentApplication] Update personal', props<{ personal: RecruitmentApplicationPersonalModel }>());

// address
export const updateAddress = createAction('[RecruitmentApplication] Update address', props<{ address: PersonalParticularAddressModel }>());

// postal
export const updatePostal = createAction('[RecruitmentApplication] Update postal', props<{ postal: string }>());
