import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectTransactionAgentModel } from '@shared/data-access/models';
import { filter, Observable, take, tap } from 'rxjs';
import { ProjectTransactionAction } from '../actions';
import { ProjectTransactionReducer } from '../reducers';
import { ProjectTransactionSelector } from '../selectors';

@Injectable()
export class ProjectTransactionFacade {
  selectedItem$ = this._store.select(ProjectTransactionSelector.selectProjectTransaction);
  constructor(private _store: Store<ProjectTransactionReducer.ProjectTransactionState>) { }

  /**
   * Get @ProjectTransactionAgentModel by id
   */
  getById(id: number): Observable<ProjectTransactionAgentModel | null> {
    this._store.dispatch(ProjectTransactionAction.loadItem({ id }));
    this._store.dispatch(ProjectTransactionAction.getBds({ transactionId: id }));
    return this.selectedItem$.pipe(
      // tap(value => console.log(value)),
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<ProjectTransactionAgentModel | null>;
  }
}
