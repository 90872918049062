import { PersonalProfileFacade } from '@agent-portal/stores';
import { UserService } from '@agent-portal/user/services';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NavigationBaseService, NAVIGATION_SERVICE } from '@shared/components/layout';
import { USER_LOADER } from '@shared/features/user';
import { PermissionsService } from '@shared/permission';
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    @Inject(NAVIGATION_SERVICE) private _navigationService: NavigationBaseService,
    @Inject(USER_LOADER) private _userService: UserService,
    private _personalProfileFacade: PersonalProfileFacade,
    private _permissionService: PermissionsService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    console.log('Resolve run');
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([this._navigationService.get(), this._userService.get(), of(this._personalProfileFacade.getPersonalProfile()), this._permissionService.get()]);
  }
}
