import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IngeniqueApiService } from './ingenique-api.service';
import { HttpClient } from '@angular/common/http';
import { IIngeniqueApiConfig } from './interfaces';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [IngeniqueApiService],
})
export class IngeniqueApiModule {
  constructor(@Optional() @SkipSelf() parentModule: IngeniqueApiModule) {
    if (parentModule) {
      throw new Error('IngeniqueApiModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config: IIngeniqueApiConfig) {
    return {
      ngModule: IngeniqueApiModule,
      providers: [
        {
          provide: IngeniqueApiService,
          useFactory: (http: HttpClient) => new IngeniqueApiService(config, http),
          deps: [HttpClient],
        },
      ],
    };
  }
}
