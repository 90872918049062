import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ResaleTransactionAction } from '../actions';
import { ResaleTransactionSelector } from '../selectors';
import { ResaleDocumentModel, ResaleExternalCoBrokeModel, ResaleTransactionModel } from '@shared/data-access/models';
import { EResaleType } from '@shared/data-access/enums';
import { IAddress, IAddressProperty, IResaleBilling, IResaleBillingMetadata, IResaleTransaction } from '@shared/data-access/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ResaleTransactionFacade {
  selectedItem$ = this._store.select(ResaleTransactionSelector.selectItemDetail);
  selectedDraftItem$ = this._store.select(ResaleTransactionSelector.selectDraftItem);
  selectedDraftType$ = this._store.select(ResaleTransactionSelector.selectDraftType);
  selectedDraftState$ = this._store.select(ResaleTransactionSelector.selectDraftState);
  selectedSubmittingStatus$ = this._store.select(ResaleTransactionSelector.selectSubmittingStatus);
  selectedBillingValueDraftItem$ = this._store.select(ResaleTransactionSelector.selectBillingValueDraftItem);
  selectedTotalExternalsReceivedPercentDraftItem$ = this._store.select(ResaleTransactionSelector.selectTotalExternalsReceivedPercentDraftItem);
  selectedTransactionAmountDraftItem$ = this._store.select(ResaleTransactionSelector.selectTransactionAmountDraftItem);
  selectedPropertyAddressDraftItem$ = this._store.select(ResaleTransactionSelector.selectPropertyAddressDraftItem);
  selectPropertyAddressFullDraftItem$ = this._store.select(ResaleTransactionSelector.selectPropertyAddressFullDraftItem);
  selectedResaleTypeDraftItem$ = this._store.select(ResaleTransactionSelector.selectResaleTypeDraftItem);
  selectedIsCobrokeDraftItem$ = this._store.select(ResaleTransactionSelector.selectIsCobrokeDraftItem);
  selectedIsWithPaymentDraftItem$ = this._store.select(ResaleTransactionSelector.selectIsWithPaymentDraftItem);
  selectedSubAmountDraftItem$ = this._store.select(ResaleTransactionSelector.selectSubAmountDraftItem);
  selectedSubTotalAmountDraftItem$ = this._store.select(ResaleTransactionSelector.selectSubTotalAmountDraftItem);
  selectedDocumentsDraftItem$ = this._store.select(ResaleTransactionSelector.selectDocumentsDraftItem);
  selectedClientType$ = this._store.select(ResaleTransactionSelector.selectClientType);
  selectedPropertyTypeDraftItem$ = this._store.select(ResaleTransactionSelector.selectPropertyTypeDraftItem);
  selectedPaymentAdviceDraftItem$ = this._store.select(ResaleTransactionSelector.selectPaymentAdviceDraftItem);
  selectedBillingDraftItem$ = this._store.select(ResaleTransactionSelector.selectBillingDraftItem);
  selectedRelatedAgentDraftItem$ = this._store.select(ResaleTransactionSelector.selectRelatedAgentDraftItem);
  selectedBillingContactDraftItem$ = this._store.select(ResaleTransactionSelector.selectBillingContactDraftItem);
  selectedBillingGstPercent$ = this._store.select(ResaleTransactionSelector.selectedBillingGstPercent);
  isCloneDuplicated$ = this._store.select(ResaleTransactionSelector.selectIsCloneDuplicated);

  constructor(private _store: Store) { }

  setItemDetail(item: ResaleTransactionModel | null) {
    this._store.dispatch(ResaleTransactionAction.setItemDetail({ item }));
  }

  loadItemDetail(id: number) {
    this._store.dispatch(ResaleTransactionAction.loadItemDetail({ id }));
  }

  updateItemDetail(data: Partial<ResaleTransactionModel>) {
    this._store.dispatch(ResaleTransactionAction.updateItemDetail({ data }));
  }

  setDraftItemAsCreate() {
    this._store.dispatch(ResaleTransactionAction.setDraftItemAsCreate());
  }

  setDraftItemAsEdit() {
    this._store.dispatch(ResaleTransactionAction.setDraftItemAsEdit());
  }

  setDraftItemAsClone() {
    this._store.dispatch(ResaleTransactionAction.setDraftItemAsClone());
  }

  updateDraftItem(data: Partial<IResaleTransaction>) {
    this._store.dispatch(ResaleTransactionAction.updateDraftItem({ data }));
  }

  updateResaleTypeDraftItem(resaleType: EResaleType) {
    this._store.dispatch(ResaleTransactionAction.updateResaleTypeDraftItem({ resaleType }));
  }

  updateTransactionAmountDraftItem(transactionAmount: number | null) {
    this._store.dispatch(ResaleTransactionAction.updateTransactionAmountDraftItem({ transactionAmount }));
  }

  updateBillingDraftItem(data: Partial<IResaleBilling>) {
    this._store.dispatch(ResaleTransactionAction.updateBillingDraftItem({ data }));
  }

  updateIsCobrokeDraftItem(isCobroke: boolean) {
    this._store.dispatch(ResaleTransactionAction.updateIsCobrokeDraftItem({ isCobroke }));
  }

  addExternalDraftItem(item: ResaleExternalCoBrokeModel) {
    this._store.dispatch(ResaleTransactionAction.addExternalDraftItem({ item }));
  }

  updateExternalDraftItem(index: number, item: ResaleExternalCoBrokeModel) {
    this._store.dispatch(ResaleTransactionAction.updateExternalDraftItem({ index, item }));
  }

  deleteExternalDraftItem(index: number) {
    this._store.dispatch(ResaleTransactionAction.deleteExternalDraftItem({ index }));
  }

  addDocumentsDraftItem(items: ResaleDocumentModel[]) {
    this._store.dispatch(ResaleTransactionAction.addDocumentsDraftItem({ items }));
  }

  deleteDocumentsDraftItem(items: ResaleDocumentModel[]) {
    this._store.dispatch(ResaleTransactionAction.deleteDocumentDraftItem({ items }));
  }

  saveDraftItem() {
    this._store.dispatch(ResaleTransactionAction.saveDraftItem());
  }

  submitDraftItem() {
    this._store.dispatch(ResaleTransactionAction.submitDraftItem());
  }

  saveAndSubmitDraftItem() {
    this._store.dispatch(ResaleTransactionAction.saveAndSubmitDraftItem());
  }

  submitItemSuccess(res: any) {
    this._store.dispatch(ResaleTransactionAction.submitItemSuccess({ res }));
  }

  submitItemFail(error: any) {
    this._store.dispatch(ResaleTransactionAction.submitItemFail({ error }));
  }

  resetDraftItem() {
    this._store.dispatch(ResaleTransactionAction.resetDraftItem());
  }

  resetSubmittingStatus() {
    this._store.dispatch(ResaleTransactionAction.resetSubmittingStatus());
  }

  updateBillingMetadataDraftItem(data: Partial<IResaleBillingMetadata>) {
    this._store.dispatch(ResaleTransactionAction.updateBillingMetadataDraftItem({ data }));
  }

  updatePropertyAddressDraftItem(address: Partial<IAddressProperty>) {
    this._store.dispatch(ResaleTransactionAction.updatePropertyAddressDraftItem({ address }));
  }

  updateBillingAddressDraftItem(address: Partial<IAddress>) {
    this._store.dispatch(ResaleTransactionAction.updateBillingAddressDraftItem({ address }));
  }

  updateMetadataDraftItem(metadata: object) {
    this._store.dispatch(ResaleTransactionAction.updateMetadataDraftItem({ metadata }));
  }

  submitWithReasonForSubmitLate() {
    this._store.dispatch(ResaleTransactionAction.submitWithReasonForSubmitLate());
  }

  loadGrossCommPreview() {
    this._store.dispatch(ResaleTransactionAction.loadGrossCommPreview());
  }

  checkDuplicateDraftItem() {
    this._store.dispatch(ResaleTransactionAction.checkDuplicateDraftItem());
  }
}
