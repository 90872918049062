import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgentProjectTransactionReducer } from '../reducers';

export const selectProjectTransactionFeature = createFeatureSelector<AgentProjectTransactionReducer.AgentProjectTransactionState>(AgentProjectTransactionReducer.featureName);

export const selectProjectTransaction = createSelector(selectProjectTransactionFeature, (state: AgentProjectTransactionReducer.AgentProjectTransactionState) => state.item);

export const selectCobrokeLeaders = createSelector(
  selectProjectTransactionFeature,
  (state: AgentProjectTransactionReducer.AgentProjectTransactionState) => state.cobrokeLeaders
);

export const selectCombinedLeaders = createSelector(
  selectProjectTransactionFeature,
  (state: AgentProjectTransactionReducer.AgentProjectTransactionState) => state.combinedLeaders
);

export const selectHasLeaders = createSelector(selectCombinedLeaders, leaders => leaders.length > 0);

export const selectAgreementDate = createSelector(selectProjectTransactionFeature, (state: AgentProjectTransactionReducer.AgentProjectTransactionState) => state.agreementDate);
