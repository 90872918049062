import { BaseModel, Default, PhoneNumber } from '@red/data-access';
import { ECommType, EResaleCommissionTaxType } from '@shared/data-access/enums';
import { Expose, Transform, Type } from 'class-transformer';
import { random } from 'lodash-es';
import { PersonalParticularModel, ProfitSplitPartModel, SplitPartModel } from '.';
import { IResaleCoBroke, IResaleCoBrokeAdvises } from '../interfaces/resale-co-broke.interface';

export class ResaleCoBrokeModel extends BaseModel implements IResaleCoBroke {
  @Expose()
  id!: number;

  @Expose()
  @Default({ agent: true, hr: true })
  absorbType!: { agent: boolean; hr: boolean };

  // @Expose()
  // @Type(() => PersonalParticularModel)
  // agent!: PersonalParticularModel;

  @Expose()
  @Default({})
  @Type(() => PersonalParticularModel)
  @Transform(({ obj, value }) => {
    if (!value || !value?.id) {
      return obj?.tiers?.[0]?.salesperson;
    }
    return value;
  })
  agent!: PersonalParticularModel;

  @Expose()
  agentId!: number;

  @Expose()
  @Transform(({ value, obj }) => value || obj?.agent?.ceaRegNo || '')
  brokerIdNo!: string;

  @Expose()
  @Transform(({ value, obj }) => value || obj?.agent?.nricName || '')
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  @Transform(({ value, obj }) => value || obj?.agent?.mobile1)
  brokerPhone!: PhoneNumber;

  @Expose()
  @Transform(({ value, obj }) => value || obj?.agent?.email1 || '')
  brokerEmail!: string;

  @Expose()
  @Transform(({ value, obj }) =>
    value
      ? {
        ...value,
        commission: {
          type:
            obj.attrs?.commission?.type ??
            (obj.commType === ECommType.value ? EResaleCommissionTaxType.number : EResaleCommissionTaxType.percentage) ??
            ECommType.percentage,
          value: obj.attrs?.commission?.value ?? +(obj.receivedPercent ?? 0),
        },
      }
      : undefined
  )
  attrs!: {
    sequence: number;
    commission?: {
      type: EResaleCommissionTaxType;
      value: number;
    };
    [key: string]: any;
  };

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  gce?: number;

  @Expose()
  commType!: ECommType;

  @Expose()
  @Default([])
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  @Default([])
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  @Default([])
  @Type(() => ProfitSplitPartModel)
  profitTiers!: ProfitSplitPartModel[];

  @Expose()
  isDefault?: boolean;

  @Expose()
  totalAmount?: number;

  @Expose()
  @Transform(({ value }) => (value ? value : random(1, 10000)))
  uniqueId?: number;

  @Expose()
  @Transform(({ obj }) => {
    if (obj.agent) return obj.agent;
    if (obj.tiers && obj.tiers.length > 0 && obj.tiers[0]?.salesperson) return obj.tiers[0]?.salesperson;
    return null;
  })
  displayedClosingAgent?: {
    id: number;
    businessName: string;
    nricName: string;
    ceaRegNo: string;
  } | null;

  @Expose()
  advises?: IResaleCoBrokeAdvises[];

  @Expose()
  grouping?: string;
}
