import { ROUTE } from '@agent-portal/constants';
import {
  AgentBlankFormFacade,
  AgentBlankFormReducer,
  AgentProjectTransactionEffect,
  AgentProjectTransactionFacade,
  AgentProjectTransactionReducer,
  BlankFormEffects,
  BlankFormFacade,
  BlankFormReducer,
  RecruitmentEffect,
  RecruitmentReducer,
  ResaleTransactionEffect,
  ResaleTransactionFacade,
  ResaleTransactionReducer,
} from '@agent-portal/stores';
import { LayoutComponent } from '@agent-portal/ui/layout';
import { NgModule, importProvidersFrom, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ExtraOptions, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CanGetAuthCode, CanRedirectToRedoc, ORIGIN_URL_REDIRECT, RedirectGuard, SSOAuthGuard, SSONoAuthGuard, TOKEN_AUTH_NAME, TokenAuthGuard, provideRedirectGuard } from '@shared/features/auth';
import { PermissionModule } from '@shared/permission';
import { Observable } from 'rxjs';
import { InitialDataResolver } from './app.resolvers';
import { environment } from '../environments/environment';
import { retryPromise } from '@shared/core';
import { DummyComponent } from '@red/components/dummy'
const routerConfig: ExtraOptions = {
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  initialNavigation: 'enabledBlocking',
};
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE.DASHBOARD,
  },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: ROUTE.DASHBOARD },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [SSONoAuthGuard],
    canActivateChild: [SSONoAuthGuard],
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: ROUTE.AUTH.LOGIN,
        canActivate: [
          (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
            // const redirectUrl = state.url === `/${this._signOutUrl}` ? '/' : state.url;
            const router = inject(Router);
            return router.navigate(['']);
          },
        ],
        loadChildren: () => retryPromise(import('./modules/auth/sign-in/sign-in.module')).then(m => m.SignInModule),
      },
      {
        path: ROUTE.AUTH.FORGOT_ACCOUNT,
        loadChildren: () => retryPromise(import('./modules/auth/forgot-account/forgot-account.module')).then(m => m.ForgotAccountModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: ROUTE.AUTH.LOGOUT,
        loadChildren: () => retryPromise(import('./modules/auth/sign-out/sign-out.module')).then(m => m.SignOutModule),
      },
      {
        path: `${ROUTE.PAYMENT.PATH}/${ROUTE.PAYMENT.TEMPLATE_PREVIEW}`,
        canActivate: [SSOAuthGuard],
        canActivateChild: [SSOAuthGuard],
        loadComponent: () => retryPromise(import('./modules/payment/payment-template-preview/payment-template-preview.component')).then(m => m.PaymentTemplatePreviewComponent),
      },
    ],
  },
  {
    path: 'document',
    canActivate: [CanGetAuthCode],
    component: DummyComponent
  },
  {
    path: 'redoc/callback',
    canActivate: [CanRedirectToRedoc],
    component: DummyComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [SSOAuthGuard],
    canActivateChild: [SSOAuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'classic',
    },
    children: [
      {
        path: ROUTE.DASHBOARD,
        loadChildren: () => retryPromise(import('./modules/dashboard/dashboard.module')).then(m => m.DashboardModule),
      },
      {
        path: 'test',
        loadChildren: () => retryPromise(import('./modules/test/test.module')).then(m => m.TestModule),
      },
      // {
      //   path: ROUTE.PROJECT.PROJECT_SETUP,
      //   loadChildren: () => retryPromise(import('./modules/project/project-setup/project-setup.module')).then(m => m.ProjectSetupModule),
      // },
      {
        path: ROUTE.PROJECT.KEY_APPOINTMENT,
        loadChildren: () => retryPromise(import('./modules/project/key-appointment/key-appointment.module')).then(m => m.KeyAppointmentModule),
      },
      {
        path: ROUTE.PROJECT.COMM_MATRIX,
        loadChildren: () => retryPromise(import('./modules/project/commission-matrix/commission-matrix.module')).then(m => m.CommissionMatrixModule),
      },
      {
        path: ROUTE.PROJECT.PROJECT_CONSULTANT,
        loadChildren: () => retryPromise(import('./modules/project/project-consultant/project-consultant.module')).then(m => m.ProjectConsultantModule),
      },
      {
        path: ROUTE.RESALE_TRANSACTION.LIST,
        loadChildren: () => retryPromise(import('./modules/resale-transaction/resale-transaction.routes')).then(m => m.RESALE_TRANSACTION_ROUTES),
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(ResaleTransactionReducer.resaleTransactionFeature),
            EffectsModule.forFeature([ResaleTransactionEffect]),
            PermissionModule.forChild({
              prefix: ['project', 'resale_transaction'],
            })
          ),
          ResaleTransactionFacade,
        ],
      },
      {
        path: ROUTE.ECDD.ROOT,
        loadChildren: () => retryPromise(import('./modules/ecdd/ecdd.routes')).then(m => m.ECDD_ROUTES),
      },
      {
        path: ROUTE.PROJECT.BLANK_FORM,
        loadChildren: () => retryPromise(import('./modules/project/blank-form/blank-form.routes')).then(m => m.BLANK_FORM_ROUTES),
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(BlankFormReducer.blankFormFeature),
            EffectsModule.forFeature([BlankFormEffects]),
            PermissionModule.forChild({
              prefix: ['project', 'bf_transaction'],
            })
          ),
          BlankFormFacade,
        ],
      },
      {
        path: ROUTE.PROJECT.AGENT_BLANK_FORM,
        loadChildren: () => retryPromise(import('./modules/project/agent-blank-form/agent-blank-form.routes')).then(m => m.AGENT_BLANK_FORM_ROUTES),
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(AgentBlankFormReducer.agentBlankFormFeature),
            PermissionModule.forChild({
              prefix: ['project', 'normal_bf_transaction'],
            })
          ),
          AgentBlankFormFacade,
        ],
      },
      {
        path: ROUTE.PROJECT.AGENT_PROJECT_TRANSACTION,
        loadChildren: () => retryPromise(import('./modules/project/agent-project-transaction/agent-project-transaction.module')).then(m => m.AgentProjectTransactionModule),
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(AgentProjectTransactionReducer.AgentprojectTransactionFeature),
            EffectsModule.forFeature([AgentProjectTransactionEffect]),
            PermissionModule.forChild({
              prefix: ['project', 'normal_transaction'],
            })
          ),
          AgentProjectTransactionFacade,
        ],
      },
      {
        path: ROUTE.PROJECT.PROJECT_TRANSACTION,
        loadChildren: () => retryPromise(import('./modules/project/project-transaction/project-transaction.module')).then(m => m.ProjectTransactionModule),
      },
      {
        path: ROUTE.PERSONAL_INFO.PATH,
        loadChildren: () => retryPromise(import('./modules/personal-infomation/personal-profile/personal-profile.module')).then(m => m.PersonalProfileModule),
      },
      {
        path: ROUTE.TEAM_ORG_CHART.PATH,
        loadChildren: () => retryPromise(import('./modules/personal-infomation/team-org-chart/team-org-chart.module')).then(m => m.TeamOrgChartModule),
      },
      {
        path: ROUTE.RECRUITMENT.PATH,
        loadChildren: () => retryPromise(import('./modules/personal-infomation/recruitment/recruitment.routes')).then(m => m.RECRUITMENT_ROUTES),
        providers: [importProvidersFrom(StoreModule.forFeature(RecruitmentReducer.recruitmentFeature), EffectsModule.forFeature([RecruitmentEffect]))],
      },
      {
        path: ROUTE.REPORT.PATH,
        loadChildren: () => retryPromise(import('./modules/personal-infomation/reports/reports.module')).then(m => m.ReportsModule),
      },
      {
        path: ROUTE.COMMISSION_PAYOUT.LIST,
        loadChildren: () => retryPromise(import('./modules/commission-payout/commission-payout.module')).then(m => m.CommissionPayoutModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: ROUTE.PUBLIC,
        children: [
          {
            path: ROUTE.RECRUITMENT.APPLICATION,
            loadChildren: () =>
              retryPromise(import('./modules/personal-infomation/recruitment/recruitment-application/recruitment-application.routes')).then(m => m.RECRUITMENT_APPLICATION_ROUTES),
          },
          {
            path: ROUTE.RECRUITMENT.PATH,
            loadChildren: () =>
              retryPromise(import('./modules/personal-infomation/recruitment/recruitment-application-confirmation/recruitment-application-confirmation.routes')).then(
                m => m.RECRUITMENT_APPLICATION_CONFIRMATION_ROUTES
              ),
          },
          {
            path: ROUTE.ECDD.ROOT,
            loadChildren: () => retryPromise(import('./modules/ecdd/ecdd-public/ecdd-public.routes')).then(m => m.ECDD_PUBLIC_ROUTES),
          },
        ],
      },
      {
        path: ROUTE.AUTH.RESET_PASSWORD,
        providers: [
          {
            provide: TOKEN_AUTH_NAME,
            useValue: 'resetToken',
          },
          TokenAuthGuard,
          ...provideRedirectGuard(environment.huttonsConnect.issuer)
        ],
        canActivate: [TokenAuthGuard, RedirectGuard],
        loadChildren: () => retryPromise(import('./modules/auth/reset-password/reset-password.module')).then(m => m.ResetPasswordModule),
      },
      // Error
      {
        path: 'error',
        children: [
          {
            path: '403',
            loadChildren: () => retryPromise(import('@shared/components/error-page')).then(m => m.Error403Module),
          },
          {
            path: '404',
            loadChildren: () => retryPromise(import('@shared/components/error-page')).then(m => m.Error404Module),
          },
          {
            path: '500',
            loadChildren: () => retryPromise(import('@shared/components/error-page')).then(m => m.Error500Module),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
