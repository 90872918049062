import { createReducer, on } from '@ngrx/store';
import { FileUploaderModel } from '@red/data-access';
import { PersonalParticularModel } from '@shared/data-access/models';
import { PersonalProfileAction } from '../actions';

export const featureName = 'personalProfile';

export interface PersonalProfileState {
  loading: boolean;
  particular: PersonalParticularModel | null;
  division: PersonalParticularModel | null;
  documents: FileUploaderModel[];
}

export const initialState: PersonalProfileState = {
  particular: null,
  division: null,
  loading: false,
  documents: [],
};

export const personalProfileReducer = createReducer(
  initialState,
  on(PersonalProfileAction.loadParticular, state => ({ ...state, loading: true })),
  on(PersonalProfileAction.loadParticularSuccess, (state, { particular }) => ({ ...state, loading: false, particular })),
  on(PersonalProfileAction.loadDivision, state => ({ ...state, loading: true })),
  on(PersonalProfileAction.loadDivisionSuccess, (state, { division }) => ({ ...state, loading: false, division })),
  on(PersonalProfileAction.loadDocuments, state => ({ ...state, loading: true })),
  on(PersonalProfileAction.loadDocumentsSuccess, (state, { documents }) => ({ ...state, loading: false, documents }))
);
