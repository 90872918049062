import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { ProjectTeamMemberModel, ProjectTransactionAgentModel, ProjectTransactionBdsModel, ProjectTransactionRelatedAgentModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[ProjectTransaction] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[ProjectTransaction] Load items success', props<{ data: PaginationAdapter<ProjectTransactionAgentModel> }>());

// item
export const loadItem = createAction('[ProjectTransaction] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[ProjectTransaction] Load item success', props<{ item: ProjectTransactionAgentModel }>());

// agreement date
export const setAgreementDate = createAction('[ProjectTransaction] SET AGREEMENT DATE', props<{ date: Date }>());

// Leaders
export const setMainLeadersFromTeamMembers = createAction(
  '[ProjectTransaction] SET MAIN LEADERS FROM TEAM MEMBERS',
  props<{ teamMembers: ProjectTeamMemberModel[] }>()
);

export const setMainLeaders = createAction('[ProjectTransaction] SET MAIN LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

export const clearMainLeaders = createAction('[ProjectTransaction] CLEAR MAIN LEADERS');

export const addCobrokeLeadersFromTeamMembers = createAction(
  '[ProjectTransaction] ADD LEADERS FROM TEAM MEMBERS',
  props<{ teamMembers: ProjectTeamMemberModel[] }>()
);

export const setCobrokeLeaders = createAction('[ProjectTransaction] SET COBROKE LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

export const removeCobrokeLeaders = createAction('[ProjectTransaction] REMOVE LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

export const clearCobrokerLeaders = createAction('[ProjectTransaction] CLEAR COBROKE LEADERS');


export const resetDraftItem = createAction('[ProjectTransaction] RESET DRAFT ITEM');

export const resetSubmittingStatus = createAction('[ProjectTransaction] RESET SUBMITTING STATUS');

export const getBds = createAction('[ProjectTransaction] GET BDS', props<{ transactionId: number }>());

export const getBdsSuccess = createAction('[ProjectTransaction] GET BDS SUCCESS', props<{ bds: ProjectTransactionBdsModel[] }>());
