import { Injectable } from '@angular/core';
import { CommissionMatrixApiService } from '@agent-portal/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, mergeMap, of, switchMap } from 'rxjs';
import { CommissionMatrixAction } from '../actions';
import { ProjectCommissionMatrixModel } from '@shared/data-access/models';
import { ECommMatrixVerifyStatus } from '@shared/data-access/enums';
import { ProjectCommissionStatusUpdateDto } from '@shared/data-access/dto';

@Injectable()
export class CommissionMatrixEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CommissionMatrixAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => CommissionMatrixAction.loadItemSuccess({ item })));
      })
    );
  });

  getAgentInfo$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CommissionMatrixAction.getAgentInfo),
      switchMap(action => {
        return this._apiService.getAgentInfo(action.id).pipe(
          map(item => CommissionMatrixAction.getAgentInfoSuccess({ item })),
          catchError(error => of(CommissionMatrixAction.getAgentInfoFailed({ error })))
        );
      })
    );
  });

  createItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CommissionMatrixAction.createItem),
      exhaustMap(action => {
        return this._apiService.create(action.data).pipe(map(item => CommissionMatrixAction.createItemSuccess({ item })));
      })
    );
  });

  updateItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CommissionMatrixAction.updateItem),
      exhaustMap(action => {
        return this._apiService.update(action.id, action.data).pipe(map(item => CommissionMatrixAction.updateItemSuccess({ item })));
      })
    );
  });

  submitItem = createEffect(() => {
    return this._actions$.pipe(
      ofType(CommissionMatrixAction.submitItem),
      exhaustMap(action => {
        return this._apiService.submit(action.payload).pipe(map(item => CommissionMatrixAction.submitItemSuccess({ item })));
      })
    );
  });

  updateAndSubmitItem = createEffect(() =>
    this._actions$.pipe(
      ofType(CommissionMatrixAction.updateAndSubmitItem),
      mergeMap(action =>
        this._apiService.update(action.id, action.updateData).pipe(
          switchMap(() => this._apiService.submit(action.submitData)),
          switchMap(res => of(CommissionMatrixAction.submitItemSuccess({ item: res }))),
          catchError(error => of(CommissionMatrixAction.submitItemFailed({ error })))
        )
      )
    )
  );

  createAndSubmitItem = createEffect(() =>
    this._actions$.pipe(
      ofType(CommissionMatrixAction.createAndSubmitItem),
      mergeMap(action =>
        this._apiService.create(action.data).pipe(
          switchMap(result => {
            const submitData = [
              {
                id: result.id,
                action: ECommMatrixVerifyStatus.submitted,
              },
            ];

            return this._apiService.submit(ProjectCommissionStatusUpdateDto.fromJson({ data: submitData }));
          }),
          switchMap(res => of(CommissionMatrixAction.submitItemSuccess({ item: res }))),
          catchError(error => of(CommissionMatrixAction.submitItemFailed({ error })))
        )
      )
    )
  );
  constructor(private _apiService: CommissionMatrixApiService, private _actions$: Actions, private _store: Store) {}
}
