import { ENV_TYPE, STORAGE_TYPE } from '@red/data-access';
import { REDOC_CONNECT_RESPONSE_TYPE } from '@shared/data-access/enums';

export const environment = {
  baseUrl: 'https://huttons-portal-dev.redoc.co',
  production: false,
  apiHost: 'https://huttons-api-gateway-dev.azure-api.net',
  storageMediaUrl: 'https://huttonsstoragedev.blob.core.windows.net',
  storageDrive: STORAGE_TYPE.AZURE,
  apiPrefix: '/api/',
  appId: 'default',
  defaultTimeZone: 'Asia/Singapore',
  name: ENV_TYPE.DEV,
  // redocRedirectUri: 'https://agent-dev.redoc.co/auth/hutton-login',
  redocRedirectUri: 'https://agent-dev.redoc.co/document',
  redocBaseUrl: 'https://agent-dev.redoc.co',
  sentryConfig: {
    // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options
    options: {
      debug: false,
      environment: "dev",
      dsn: "https://30cdae5e339a441ff1e69bf4e35288f4@o434962.ingest.us.sentry.io/4507094199959552"
    }
  },
  ingeniqueApiConfig: {
    token:
      'eyJhbGciOiJIUzUxMiJ9.eyJhY2NvdW50SWQiOiIzMDBsaXZlIiwiaWF0IjoxNjcwNTY4ODc4LCJ1c2VySWQiOiI5MDkifQ.hpDdfxfmSKI7YB8yYBUz0pj-ONNJxoyyTqks6nxib9WHF1kTzbf97mE8OXOvrhNWOBbrfDqiI86Ecyx4-ZPBAw',
    apiKey: 'oo7KUatYqm3iLra6MhLta2BAeh6lIpUH9aoBTXg8',
    sectorId: 'SGDJ',
    accountName: '300live',
  },
  redocConnect: {
    issuer: 'https://id-dev.redoc.co',
    loginUrl: `https://id-dev.redoc.co/authorize`,
    logoutUrl: `https://id-dev.redoc.co/sign-out`,
    authorizeUrl: `https://id-dev.redoc.co/authorize`,
    // redirectUri: window.location.origin + '/login',
    redirectUri: window.location.origin + '/redoc/callback',
    postLogoutRedirectUri: window.location.origin + '/login?forcedLogin=true',
    clientId: 'dev.portal.huttons',
    responseType: REDOC_CONNECT_RESPONSE_TYPE.CODE,
    scope: 'redocid profile email api',
    showDebugInformation: true,
  },
  huttonsConnect: {
    issuer: 'https://huttons-id-dev.redoc.co',
    logoutUrl: `https://huttons-id-dev.redoc.co/sign-out`,
    redirectUri: window.location.origin + '/login',
    clientId: 'dev.portal.huttons',
    responseType: REDOC_CONNECT_RESPONSE_TYPE.CODE,
    scope: 'redocid profile email api',
    showDebugInformation: true,
    tokenSetConfig: {
      prefix: 'hits',
    },
  },
  hipUrl: 'https://uat.huttonsgroup.com'

};
