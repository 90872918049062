import { createFeature, createReducer, on } from '@ngrx/store';
import { KeyAppointmentModel } from '@shared/data-access/models';
import { KeyAppointmentAction } from '../actions';

export const featureName = 'keyAppointment';

export interface KeyAppointmentState {
  item: KeyAppointmentModel | null;
}
export const initialState: KeyAppointmentState = {
  item: null,
};
export const keyAppointmentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(KeyAppointmentAction.loadItemSuccess, (state, prop: { item: KeyAppointmentModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = keyAppointmentFeature;
