// core
import { createFeature, createReducer, on } from '@ngrx/store';

// share
import {
  RecruitmentApplicationPersonalModel,
  PersonalParticularAddressModel,
  RecruitmentApplicationModel,
  RecruitmentModel,
  TemplateModel,
} from '@shared/data-access/models';
import { RecruitmentApplicationAction } from '../actions';

export const featureName = 'recruitment-application';

export interface RecruitmentApplicationState {
  detail: RecruitmentApplicationModel | null;
  recruitment: RecruitmentModel | null;
  personal: RecruitmentApplicationPersonalModel | null;
  address: PersonalParticularAddressModel | null;
  postal: string | null;
  templates: TemplateModel[] | null;
  error: unknown;
}

export const initialState: RecruitmentApplicationState = {
  detail: null,
  recruitment: null,
  personal: null,
  address: null,
  postal: null,
  templates: null,
  error: null,
};

export const recruitmentApplicationFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(RecruitmentApplicationAction.loadDetailSuccess, (state, { detail }) => ({ ...state, detail })),
    on(RecruitmentApplicationAction.loadRecruitmentSuccess, (state, { recruitment }) => ({ ...state, recruitment })),
    on(RecruitmentApplicationAction.loadTemplateSuccess, (state, { templates }) => ({ ...state, templates })),
    on(RecruitmentApplicationAction.loadRecruitmentFailure, (state, { error }) => ({ ...state, error })),

    on(RecruitmentApplicationAction.updatePersonal, (state, { personal }) => ({ ...state, personal })),
    on(RecruitmentApplicationAction.updateAddress, (state, { address }) => ({ ...state, address })),
    on(RecruitmentApplicationAction.updatePostal, (state, { postal }) => ({ ...state, postal }))
  ),
});
