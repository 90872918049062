import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RecruitmentReducer } from '../reducers';
import { RecruitmentSelector } from '../selectors';

@Injectable()
export class RecruitmentFacade {
  recruitment$ = this._store.select(RecruitmentSelector.selectRecruitmentDetail);

  constructor(private _store: Store<RecruitmentReducer.RecruitmentState>) {}
}
