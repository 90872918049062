import { createAction, props } from '@ngrx/store';
import { ResaleDocumentModel, ResaleExternalCoBrokeModel, ResaleTransactionModel } from '@shared/data-access/models';
import { EResaleType } from '@shared/data-access/enums';
import { IAddress, IAddressProperty, IInvoiceOption, IResaleBilling, IResaleBillingMetadata, IResaleTransaction } from '@shared/data-access/interfaces';

export const setItemDetail = createAction('[Resale Transaction] SET ITEM DETAIL', props<{ item: ResaleTransactionModel | null }>());

export const loadItemDetail = createAction('[Resale Transaction] LOAD ITEM DETAIL', props<{ id: number }>());

export const updateItemDetail = createAction('[Resale Transaction] UPDATE ITEM DETAIL', props<{ data: Partial<ResaleTransactionModel> }>());

export const setDraftItemAsCreate = createAction('[Resale Transaction] SET DRAFT ITEM AS CREATE');

export const setDraftItemAsEdit = createAction('[Resale Transaction] SET DRAFT ITEM AS EDIT');

export const setDraftItemAsClone = createAction('[Resale Transaction] SET DRAFT ITEM AS CLONE');

export const updateDraftItem = createAction('[Resale Transaction] UPDATE DRAFT ITEM', props<{ data: Partial<IResaleTransaction> }>());

export const updateResaleTypeDraftItem = createAction('[Resale Transaction] UPDATE RESALE TYPE DRAFT ITEM', props<{ resaleType: EResaleType }>());

export const updateTransactionAmountDraftItem = createAction(
  '[Resale Transaction] UPDATE TRANSACTION AMOUNT DRAFT ITEM',
  props<{ transactionAmount: number | null }>()
);

export const updateBillingDraftItem = createAction('[Resale Transaction] UPDATE BILLING DRAFT ITEM', props<{ data: Partial<IResaleBilling> }>());

export const updateIsCobrokeDraftItem = createAction('[Resale Transaction] UPDATE IS COBROKE DRAFT ITEM', props<{ isCobroke: boolean }>());

export const addExternalDraftItem = createAction('[Resale Transaction] ADD EXTERNAL DRAFT ITEM', props<{ item: ResaleExternalCoBrokeModel }>());

export const updateExternalDraftItem = createAction('[Resale Transaction] UPDATE EXTERNAL DRAFT ITEM', props<{ index: number; item: ResaleExternalCoBrokeModel }>());

export const deleteExternalDraftItem = createAction('[Resale Transaction] DELETE EXTERNAL DRAFT ITEM', props<{ index: number }>());

export const addDocumentsDraftItem = createAction('[Resale Transaction] ADD DOCUMENTS DRAFT ITEM', props<{ items: ResaleDocumentModel[] }>());

export const deleteDocumentDraftItem = createAction('[Resale Transaction] DELETE DOCUMENT DRAFT ITEM', props<{ items: ResaleDocumentModel[] }>());

export const saveDraftItem = createAction('[Resale Transaction] SAVE DRAFT ITEM');

export const submitDraftItem = createAction('[Resale Transaction] SUBMIT DRAFT ITEM');

export const saveAndSubmitDraftItem = createAction('[Resale Transaction] SAVE AND SUBMIT DRAFT ITEM');

export const submitItemSuccess = createAction('[Resale Transaction] SUBMIT DRAFT ITEM SUCCESS', props<{ res: any }>());

export const submitItemFail = createAction('[Resale Transaction] SUBMIT DRAFT ITEM FAIL', props<{ error: any }>());

export const resetDraftItem = createAction('[Resale Transaction] RESET DRAFT ITEM');

export const resetSubmittingStatus = createAction('[Resale Transaction] RESET SUBMITTING STATUS');

export const updateBillingMetadataDraftItem = createAction(
  '[Resale Transaction] UPDATE BILLING METADATA DRAFT ITEM',
  props<{ data: Partial<IResaleBillingMetadata> }>()
);

export const updatePropertyAddressDraftItem = createAction('[Resale Transaction] UPDATE PROPERTY ADDRESS DRAFT ITEM', props<{ address: Partial<IAddressProperty> }>());

export const updateBillingAddressDraftItem = createAction('[Resale Transaction] UPDATE BILLING ADDRESS DRAFT ITEM', props<{ address: Partial<IAddress> }>());

export const updateMetadataDraftItem = createAction('[Resale Transaction] UPDATE METADATA DRAFT ITEM', props<{ metadata: object }>());

export const submitWithReasonForSubmitLate = createAction('[Resale Transaction] SUBMIT WITH REASON FOR SUBMIT LATE');

export const loadGrossCommPreview = createAction('[Resale Transaction] LOAD GROSS COMM PREVIEW');

export const loadGrossCommPreviewFail = createAction('[Resale Transaction] LOAD GROSS COMM PREVIEW FAIL', props<{ error: any }>());

export const checkDuplicateDraftItem = createAction('[Resale Transaction] CHECK DUPLICATE DRAFT ITEM');

export const loadPublicLinkDocument = createAction('[Resale Transaction] LOAD PUBLIC LINK DOCUMENT', props<{ documentId: number }>());
