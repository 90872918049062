import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BlankFormReducer } from '../reducers';
import { EProjectType } from '@shared/data-access/enums';

export const selectBlankFormFeature = createFeatureSelector<BlankFormReducer.BlankFormState>(BlankFormReducer.featureName);

export const selectItemDetail = createSelector(selectBlankFormFeature, ({ item }) => item);

export const selectBlankFormId = createSelector(selectBlankFormFeature, ({ item }) => item?.id);

export const selectDraftItem = createSelector(selectBlankFormFeature, ({ draftItem }) => draftItem);

export const selectDraftType = createSelector(selectBlankFormFeature, ({ draftType }) => draftType);

export const selectDraftState = createSelector(selectBlankFormFeature, ({ draftItem, draftType }) => ({
  draftItem,
  draftType,
}));

export const selectSubmittingStatus = createSelector(selectBlankFormFeature, ({ submittingStatus }) => submittingStatus);

export const selectIsInternationalProjectDraftItem = createSelector(selectDraftItem, draftItem => draftItem.project?.entity === EProjectType.INTERNATIONAL);
