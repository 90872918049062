export enum TemplateType {
  INVOICE = 'INVOICE',
  SUPPLIER_INVOICE = 'SUPPLIER_INVOICE',
  CUSTOMER_CREDIT_NOTE = 'CUSTOMER_CREDIT_NOTE',
  SUPPLIER_CREDIT_NOTE = 'SUPPLIER_CREDIT_NOTE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  RECEIPT = 'RECEIPT',
  PAYMENT = 'PAYMENT',
  Protection = 'Protection',
  Authorisation = 'Authorisation',
  Agreement = 'Agreement',
  INVOICE_PROFORMA = 'INVOICE_PROFORMA',
  PAYMENTADVICE = 'PaymentAdvice',
  MISC_TAX_INVOICE_HI = 'misc_tax_invoice_hi',
  MISC_BANK_REFERRAL_FEETAX_INVOICE_HI = 'misc_bank_referral_feetax_invoice_hi'
}
