import { createAction, props } from '@ngrx/store';
import { PaginationAdapter } from '@red/data-access';
import { ProjectCommissionMatrixCreateDto, ProjectCommissionMatrixUpdateDto, ProjectCommissionStatusUpdateDto } from '@shared/data-access/dto';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { ProjectCommissionMatrixModel } from '@shared/data-access/models';

// Get items
export const loadItems = createAction('[CommissionMatrix] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[CommissionMatrix] Load items success', props<{ data: PaginationAdapter<ProjectCommissionMatrixModel> }>());

// Get item
export const loadItem = createAction('[CommissionMatrix] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[CommissionMatrix] Load item success', props<{ item: ProjectCommissionMatrixModel }>());

// Get Agent Info
export const getAgentInfo = createAction('[CommissionMatrix] Get Agent Info', props<{ id: number }>());
export const getAgentInfoSuccess = createAction('[CommissionMatrix] Get Agent Info success', props<{ item: ProjectCommissionMatrixModel }>());
export const getAgentInfoFailed = createAction('[CommissionMatrix] Get Agent Info failed', props<{ error: any }>());
export const resetAgentInfo = createAction('[CommissionMatrix] Reset Agent Info');

// Create item
export const createItem = createAction('[CommissionMatrix] Create item', props<{ data: ProjectCommissionMatrixCreateDto }>());
export const createItemSuccess = createAction('[CommissionMatrix] Create item success', props<{ item: ProjectCommissionMatrixModel }>());
export const createItemFailed = createAction('[CommissionMatrix] Create item failed', props<{ error: any }>());

// Update item
export const updateItem = createAction('[CommissionMatrix] Update item', props<{ id: number; data: ProjectCommissionMatrixUpdateDto }>());
export const updateItemSuccess = createAction('[CommissionMatrix] Update item success', props<{ item: ProjectCommissionMatrixModel }>());
export const updateItemFailed = createAction('[CommissionMatrix] Create item failed', props<{ error: any }>());

// postAction
export const submitItem = createAction('[CommissionMatrix] Submit item', props<{ payload: ProjectCommissionStatusUpdateDto }>());
export const submitItemSuccess = createAction('[CommissionMatrix] Submit item success', props<{ item: ProjectCommissionMatrixModel }>());
export const submitItemFailed = createAction('[CommissionMatrix] Submit item failed', props<{ error: any }>());

// Update And Submit item
export const createAndSubmitItem = createAction('[CommissionMatrix] Create and Submit item', props<{ data: ProjectCommissionMatrixCreateDto }>());

export const updateAndSubmitItem = createAction(
  '[CommissionMatrix] Update and Submit item',
  props<{ id: number; updateData: ProjectCommissionMatrixUpdateDto; submitData: ProjectCommissionStatusUpdateDto }>()
);
