import { APP_CONFIG, IAppConfig } from '@agent-portal/config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { ENV_TYPE } from '@red/data-access';
import { IPermission, PermissionLoader } from '@shared/permission';
import { flatten, isNil } from 'lodash-es';
import { debounceTime, map, Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionHttpLoader extends PermissionLoader {
  constructor(private _apiService: ApiService, private _httpClient: HttpClient, @Inject(APP_CONFIG) private _appConfig: IAppConfig) {
    super();
  }

  override get(): Observable<IPermission[]> {
    if (this._appConfig.name === ENV_TYPE.LOCAL) {
      return this.fromJsonFile();
    }
    return this.fromApi();
  }

  private fromApi(): Observable<IPermission[]> {
    // return this._apiService.get('settings/profile/permissions/ic');
    return forkJoin([
      this._apiService.get('settings/profile/permissions') as Observable<IPermission[]>,
      this._apiService.get('settings/profile/permissions/ic') as Observable<IPermission[]>,
    ]).pipe(
      map(permissions => {
        console.log('permissions', permissions);
        return flatten(permissions);
      })
    );
  }
  private fromJsonFile(): Observable<IPermission[]> {
    let nextId = 10000;
    return this._httpClient.get<{ RECORDS: IPermission[] }>('assets/sample/__permission.data.json').pipe(
      debounceTime(300),
      map(data => data.RECORDS),
      map(data =>
        data.map(item => {
          if (isNil(item.id)) {
            item.id = nextId;
            nextId++;
          }
          return item;
        })
      )
    );
  }
}
