import { Injectable } from '@angular/core';
import { ProjectTransactionApiService } from '@agent-portal/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map, mergeMap } from 'rxjs';
import { ProjectTransactionAction } from '../actions';

@Injectable()
export class ProjectTransactionEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectTransactionAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => ProjectTransactionAction.loadItemSuccess({ item })));
      })
    );
  });
  getBds$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectTransactionAction.getBds),
      mergeMap(action =>
        this._apiService.getBds(action.transactionId).pipe(
          map(data => ProjectTransactionAction.getBdsSuccess({ bds: data })),
        )
      )
    );
  });
  constructor(private _apiService: ProjectTransactionApiService, private _actions$: Actions, private _store: Store) { }
}
