import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IIngeniqueApiConfig, IIngeniqueApiSearchAmlQuery, IIngeniqueApiSearchAmlResponse } from './interfaces/ingenique-api.interface';
import { INGENIQUE_API_CONFIG } from './token';

@Injectable()
export class IngeniqueApiService {
  // Documentation: https://api.ingenique.asia/documentation

  static SEARCH_AML_URL = 'https://api.ingenique.asia/sentroweb-service/screening/v1.0/search';

  constructor(@Inject(INGENIQUE_API_CONFIG) private _config: IIngeniqueApiConfig, private _http: HttpClient) {}

  searchAml(query: IIngeniqueApiSearchAmlQuery) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this._config.token}`,
      'x-api-key': this._config.apiKey,
      'x-sector-id': this._config.sectorId,
      'X-account-name': this._config.accountName,
    });
    return this._http.post<IIngeniqueApiSearchAmlResponse[]>(IngeniqueApiService.SEARCH_AML_URL, { queries: [query] }, { headers });
  }
}
