/* eslint-disable no-useless-escape */
import { EnhancedPercentModule } from '@red/pipes/percent';
import { APP_DATE_FORMATS, DateFnsDateAdapter } from '@shared/core';
import { dateTimeConfig, HTTP_ERROR, ROUTE } from '@agent-portal/constants';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { RouteLoadingModule } from '@shared/components/router-loading';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { ConfigModule, getAppConfigProvider, getDateTimeAdapterProvider, getDateTimeConfigProvider } from '@agent-portal/config';
import { AuthModule, AuthWithRefreshTokenInterceptor, useUathApiLoader, OauthModule } from '@shared/features/auth';
import { AuthService } from '@agent-portal/auth/services';
import { useUserAdapterService } from '@shared/features/user';
import { UserService } from '@agent-portal/user/services';
import { RedLayoutConfigModule } from '@red/components/layout-config';
import { appConfig, getNavigationService } from '@shared/components/layout';
import { RedIconModule } from '@red/components/icon';
import { CUSTOME_ICON_DATA } from '@agent-portal/core';
import { RedApiModule } from '@red/api';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { NavigationPermissionService, NavigationService } from '@agent-portal/ui/navigation';
import { RedMediaWatcherModule } from '@red/components/media-watcher';
import { LayoutModule } from '@agent-portal/ui/layout';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslocoModuleCore } from '@shared/components/transloco';
import { EmptyModule } from '@red/pipes/empty';
import { IConfig, initialConfig, NgxMaskModule } from 'ngx-mask';
import { ENV_TYPE, MediaStorageModule } from '@redoc-starter/redoc';
import { EcddEffect, EcddReducer, PersonalProfileEffect, PersonalProfileReducer, PhoneNumberEffects, PhoneNumberReducer } from '@agent-portal/stores';
import { IngeniqueApiModule } from '@shared/components/ingenique-api';
import { PageTitleModule } from '@shared/features/page-title';
import { PermissionModule, PERMISSION_LOADER } from '@shared/permission';
import { PermissionHttpLoader } from '@agent-portal/permissions';
import { EnhancedNumberModule } from '@shared/pipes/enhanced-number';
import { HttpErrorWrapperInterceptor, HttpErrorInterceptor, HTTP_STATUS_CODE_EXCLUDE } from '@shared/components/error-page';
import { NavigationExtras, Router } from '@angular/router';
import { forcedLogoutFn } from '@agent-portal/auth/data-access';
import { BroadcastChannelModule } from '@shared/components/broadcast-channel';
import { DocumentVisibilityModule } from '@shared/components/document-visibility';
import { RedocConnectModule } from '@shared/components/redoc-connect';
import { IRedocOauth2Config } from '@redocco/oauth2-redoc-connect';
import { ExceljsViewerModule } from '@shared/components/exceljs-viewer';
import { SentryCoreModule } from '@shared/components/sentry';
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: true,
    thousandSeparator: ',',
    separatorLimit: '999999999999999',
    patterns: {
      ...initialConfig.patterns,
      A: {
        pattern: new RegExp(/[a-zA-Z0-9\s\.\,\-]/),
      },
      E: {
        pattern: /[a-zA-Z0-9\.\_\-\@]/,
      },
    },
  };
};

export const authCodeFlowConfigDev: Partial<IRedocOauth2Config> = environment.redocConnect;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RedLayoutConfigModule.forRoot(appConfig),
    LayoutModule,
    RedIconModule.forRoot(CUSTOME_ICON_DATA),
    RedApiModule.forRoot({
      apiHost: environment.apiHost,
      urlPrefix: environment.apiPrefix,
      timeout: 90000,
      appId: environment.appId,
    }),
    StoreModule.forRoot({
      [EcddReducer.featureName]: EcddReducer.reducer,
      [PersonalProfileReducer.featureName]: PersonalProfileReducer.personalProfileReducer,
      phoneNumber: PhoneNumberReducer.phoneNumberReducer,
    }),
    EffectsModule.forRoot([PhoneNumberEffects, PersonalProfileEffect, EcddEffect]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      name: 'Huttons Agent DevTool',
      // logOnly: environment.production, // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    ConfigModule.forRoot({
      load: [
        getAppConfigProvider(environment),
        useUserAdapterService(UserService),
        getNavigationService(NavigationPermissionService),
        getDateTimeConfigProvider(dateTimeConfig),
        ...getDateTimeAdapterProvider({ adapter: DateFnsDateAdapter, format: APP_DATE_FORMATS }),
      ],
    }),
    AuthModule.forSSOFeature({
      configSSO: environment.huttonsConnect,
      serviceInstance: useUathApiLoader(AuthService),
      loginRoute: ROUTE.AUTH.LOGIN,
      logoutRoute: ROUTE.AUTH.LOGOUT,
      forcedLogoutFn: forcedLogoutFn,
      customAuthInterceptor: true,
    }),
    TranslocoModuleCore.forRoot({ prodMode: environment.production }),
    RedMediaWatcherModule,
    RouteLoadingModule,
    AppRoutingModule,
    EmptyModule.forRoot({
      defaultValue: '-',
    }),
    EnhancedPercentModule.forRoot({
      multipliedBy100: true,
    }),
    EnhancedNumberModule.forRoot({
      defaultNumberFormat: '1.2-2',
    }),
    NgxMaskModule.forRoot(maskConfigFunction),
    MediaStorageModule.forRoot({
      storageDrive: environment.storageDrive,
    }),
    PageTitleModule.forRoot({
      prefix: environment.name !== ENV_TYPE.PROD ? environment.name : '',
      default: 'Agent Portal',
    }),
    IngeniqueApiModule.forRoot(environment.ingeniqueApiConfig),
    PermissionModule.forRoot({
      redirectCommand: ['error', '403'],
    }),
    BroadcastChannelModule.forRoot({
      channelNames: ['appState'],
    }),
    DocumentVisibilityModule,
    // OauthModule.forRoot(environment.huttonsConnect),
    RedocConnectModule.forRoot(environment.redocBaseUrl, authCodeFlowConfigDev),
    ExceljsViewerModule.forRoot({
      origin: environment.baseUrl,
    }),
    SentryCoreModule.forRoot(environment.sentryConfig.options)
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorWrapperInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthWithRefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_STATUS_CODE_EXCLUDE,
      useValue: HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE,
    },
    {
      provide: PERMISSION_LOADER,
      useClass: PermissionHttpLoader,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
