import { EcddApiService, EcddStorageApiService } from '@agent-portal/apis';
import { UPLOAD_FOLDER } from '@agent-portal/constants';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EcddCustomerDto, EcddCustomerSignatureDto, EcddDto } from '@shared/data-access/dto';
import { EEcddResponseType } from '@shared/data-access/enums';
import { HuttonLinkUploadResponse } from '@shared/features/media-storage';
import { catchError, forkJoin, from, iif, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { EcddAction } from '../actions';
import { EcddSelector } from '../selectors';

@Injectable()
export class EcddEffect {
  actions$ = inject(Actions);
  store = inject(Store);
  apiService = inject(EcddApiService);
  ecddStorageApiService = inject(EcddStorageApiService);
  httpClient = inject(HttpClient);

  loadCustomerSignatureDataWhenInitDraftState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcddAction.initDraftState),
      concatLatestFrom(() => this.store.select(EcddSelector.selectCustomer)),
      mergeMap(([_, customer]) =>
        iif(
          () => !!customer?.signature,
          this.ecddStorageApiService.getPublicLink({
            keyName: customer?.signature ?? '',
            filename: customer?.signature ?? '',
            folder: UPLOAD_FOLDER.SIGNATURE,
          }),
          of(null)
        ).pipe(
          switchMap(url =>
            iif(() => !!url, this.httpClient.get(url as string, { responseType: 'blob' }).pipe(switchMap(res => this._convertBlobToBase64(res))), of(null))
          ),
          switchMap(res => of(EcddAction.setCustomerSignatureData({ data: res }))),
          catchError(() => of(EcddAction.setCustomerSignatureData({ data: null })))
        )
      )
    )
  );

  saveDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcddAction.saveDraftItem),
      concatLatestFrom(() => this.store.select(EcddSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType, ecddType, isCustomerSigned, customerSignatureData, customer, resSignatureData }]) => {
        let customerSignatureFile: File | undefined;
        let resSignatureFile: File | undefined;
        const uploadApis: Observable<HuttonLinkUploadResponse | null>[] = [of(null), of(null)];
        if (!isCustomerSigned && customerSignatureData) {
          customerSignatureFile = this._createFileFromBase64(customerSignatureData);
          uploadApis[0] = this.ecddStorageApiService.upload(customerSignatureFile);
        }
        // if (!draftItem.signature && resSignatureData) {
        //   resSignatureFile = this._createFileFromBase64(resSignatureData);
        //   uploadApis[1] = this.ecddStorageApiService.upload(resSignatureFile);
        // }
        if (resSignatureData) {
          resSignatureFile = this._createFileFromBase64(resSignatureData);
          uploadApis[1] = this.ecddStorageApiService.upload(resSignatureFile);
        }
        return forkJoin(uploadApis).pipe(
          switchMap(([customerSignatureRes, resSignatureRes]) => {
            const customerDto = EcddCustomerDto.fromJson({
              ...customer,
              signature: customerSignatureRes?.fields.key ?? customer?.signature ?? '',
            });
            const dto = EcddDto.fromJson({
              ...draftItem,
              type: ecddType,
              customers: [customerDto],
              signature: resSignatureRes?.fields.key ?? draftItem.signature ?? '',
            });
            return iif(() => draftType === 'create', this.apiService.create(dto), this.apiService.update(draftItem.id, dto));
          }),
          switchMap(res =>
            of(
              EcddAction.submitItemSuccess({
                res,
              })
            )
          ),
          catchError(error => of(EcddAction.submitItemFail({ error })))
        );
      })
    )
  );

  saveAndSubmitDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcddAction.saveAndSubmitDraftItem),
      concatLatestFrom(() => this.store.select(EcddSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType, ecddType, isCustomerSigned, customerSignatureData, customer, resSignatureData }]) => {
        let customerSignatureFile: File | undefined;
        let resSignatureFile: File | undefined;
        const uploadApis: Observable<HuttonLinkUploadResponse | null>[] = [of(null), of(null)];
        if (!isCustomerSigned && customerSignatureData) {
          customerSignatureFile = this._createFileFromBase64(customerSignatureData);
          uploadApis[0] = this.ecddStorageApiService.upload(customerSignatureFile);
        }
        if (resSignatureData) {
          resSignatureFile = this._createFileFromBase64(resSignatureData);
          uploadApis[1] = this.ecddStorageApiService.upload(resSignatureFile);
        }
        return forkJoin(uploadApis).pipe(
          switchMap(([customerSignatureRes, resSignatureRes]) => {
            const customerDto = EcddCustomerDto.fromJson({
              ...customer,
              signature: customerSignatureRes?.fields.key ?? customer?.signature ?? '',
            });
            const dto = EcddDto.fromJson({
              ...draftItem,
              type: ecddType,
              customers: [customerDto],
              signature: resSignatureRes?.fields.key ?? draftItem.signature ?? '',
            });
            return iif(() => draftType === 'create', this.apiService.create(dto), this.apiService.update(draftItem.id, dto));
          }),
          switchMap(item => this.apiService.submit(item.id)),
          switchMap(res =>
            of(
              EcddAction.submitItemSuccess({
                res,
              })
            )
          ),
          catchError(error => of(EcddAction.submitItemFail({ error })))
        );
      })
    )
  );

  savePublicDraftItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcddAction.savePublicDraftItem),
      concatLatestFrom(() => this.store.select(EcddSelector.selectDraftState)),
      mergeMap(([_, { draftItem, customerSignatureData, customer }]) => {
        const customerSignatureFile = this._createFileFromBase64(customerSignatureData);
        return this.ecddStorageApiService.publicUpload(customerSignatureFile).pipe(
          switchMap(res => {
            const customerSignatureDto = EcddCustomerSignatureDto.fromJson({
              id: customer?.id ?? 0,
              signature: res?.fields.key ?? '',
            });
            return this.apiService.updateCustomerSignature(draftItem.isOld ? draftItem.id : draftItem.uuid, customerSignatureDto);
          }),
          switchMap(res =>
            of(
              EcddAction.submitItemSuccess({
                res: {
                  type: EEcddResponseType.customerSigned,
                  data: res,
                },
              })
            )
          ),
          catchError(error => of(EcddAction.submitItemFail({ error })))
        );
      })
    )
  );

  saveAndCreateUrlForCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcddAction.saveAndCreateUrlForCustomer),
      concatLatestFrom(() => this.store.select(EcddSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType, ecddType, customer }]) => {
        const customerDto = EcddCustomerDto.fromJson(customer);
        const dto = EcddDto.fromJson({ ...draftItem, type: ecddType, customers: [customerDto] });
        return iif(
          () => draftType === 'create',
          this.apiService.create(dto).pipe(switchMap(item => this.apiService.get(item.id))),
          this.apiService.update(draftItem.id, dto).pipe(map(() => draftItem))
        ).pipe(
          switchMap(item =>
            of(
              EcddAction.updateCustomer({ data: { id: item.customers[0].id } }),
              EcddAction.setDraftType({ draftType: 'edit' }),
              EcddAction.updateDraftItem({ data: { id: item.id } }),
              EcddAction.submitItemSuccess({
                res: {
                  type: EEcddResponseType.sendUrlForCustomer,
                  url: `${window.location.origin}/public/ecdd/customer-particulars-form/${item.uuid}`,
                  id: item.id,
                },
              })
            )
          ),
          catchError(error => of(EcddAction.submitItemFail({ error })))
        );
      })
    )
  );

  saveAndProceedToChecklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcddAction.saveAndProceedToChecklist),
      concatLatestFrom(() => this.store.select(EcddSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType, ecddType, isCustomerSigned, customerSignatureData, customer }]) => {
        let signatureFile = new File([], '');
        if (!isCustomerSigned && customerSignatureData) {
          signatureFile = this._createFileFromBase64(customerSignatureData);
        }
        return iif(() => !isCustomerSigned && customerSignatureData, this.ecddStorageApiService.upload(signatureFile), of(null)).pipe(
          switchMap(customerSignatureRes => {
            const customerDto = EcddCustomerDto.fromJson({
              ...customer,
              signature: customerSignatureRes?.fields.key ?? customer?.signature ?? '',
            });
            const dto = EcddDto.fromJson({ ...draftItem, type: ecddType, customers: [customerDto] });
            return iif(
              () => draftType === 'create',
              this.apiService.create(dto).pipe(switchMap(item => this.apiService.get(item.id))),
              this.apiService.update(draftItem.id, dto).pipe(map(() => draftItem))
            );
          }),
          switchMap(item =>
            of(
              EcddAction.submitItemSuccess({
                res: {
                  type: EEcddResponseType.proceedToChecklist,
                  id: item.id,
                },
              }),
              EcddAction.resetSubmittingStatus()
            )
          ),
          catchError(error => of(EcddAction.submitItemFail({ error })))
        );
      })
    )
  );

  private _convertBlobToBase64(blob: Blob) {
    return from(
      new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      })
    );
  }

  private _createFileFromBase64(imageBase64: string): File {
    const fileName = new Date().getTime() + Math.random().toString().substring(2, 10) + '.png';
    const byteString = atob(imageBase64.split(',')[1]);
    const mimeString = imageBase64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }
}
