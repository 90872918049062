import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgentBlankFormReducer } from '../reducers';

export const selectAgentBlankFormFeature = createFeatureSelector<AgentBlankFormReducer.BlankFormState>(AgentBlankFormReducer.featureName);

export const selectItemDetail = createSelector(selectAgentBlankFormFeature, ({ item }) => item);

export const selectBlankFormId = createSelector(selectAgentBlankFormFeature, ({ item }) => item?.blankForm?.id);



