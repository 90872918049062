import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectConsultantModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { ProjectConsultantAction } from '../actions';
import { ProjectConsultantReducer } from '../reducers';
import { ProjectConsultantSelector } from '../selectors';

@Injectable()
export class ProjectConsultantFacade {
  selectedItem$ = this._store.select(ProjectConsultantSelector.selectProjectConsultant);
  countMember$ = this._store.select(ProjectConsultantSelector.selectCountMember);
  leaders$ = this._store.select(ProjectConsultantSelector.selectTeamLeader);
  constructor(private _store: Store<ProjectConsultantReducer.ProjectConsultantState>) {}

  /**
   * Get @ProjectConsultantModel by id
   */
  getById(id: number): Observable<ProjectConsultantModel | null> {
    this._store.dispatch(ProjectConsultantAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<ProjectConsultantModel | null>;
  }

  getLeader(teamId: number): void {
    this._store.dispatch(ProjectConsultantAction.getLeader({ teamId }));
  }

  getNumberOfMember(teamId: number): void {
    this._store.dispatch(ProjectConsultantAction.countMember({ teamId }));
  }
}
