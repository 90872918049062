import { ResaleTransactionApiService, TransactionStorageApiService } from '@agent-portal/apis';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResaleTransactionPreviewDto } from '@shared/data-access/dto';
import { ResaleCoBrokeModel, ResaleDocumentModel, ResaleExternalCoBrokeModel, ResaleRelatedAgentModel, ResaleTransactionModel } from '@shared/data-access/models';
import { omit } from 'lodash-es';
import { forkJoin, iif, of, switchMap } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ResaleTransactionAction } from '../actions';
import { PersonalProfileSelector, ResaleTransactionSelector } from '../selectors';
import { PhoneNumber } from '@red/data-access';

@Injectable()
export class ResaleTransactionEffect {
  // loadItemDetail$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ResaleTransactionAction.loadItemDetail),
  //     mergeMap(({ id }) =>
  //       this.apiService.get(id).pipe(
  //         switchMap(item => {
  //           const { status } = item;
  //           // const isHaveRejectReason = [EResaleStatus.rework, EResaleStatus.rework2, EResaleStatus.rejected].includes(status);
  //           const isHaveRejectReason = [EResaleStatus.sendBack, EResaleStatus.rework, EResaleStatus.rejected].includes(status);
  //           return forkJoin([
  //             of(item),
  //             this.apiService.getBillingsByResaleId(id),
  //             this.apiService
  //               .searchDocuments({ resaleIds: id })
  //               .pipe(
  //                 switchMap(res =>
  //                   forkJoin(res.results.map(({ url }) => this.storageApi.getPublicLink({ keyName: url }))).pipe(
  //                     map(urls => res.results.map((item, index) => ResaleDocumentModel.merge(item, { publicLink: urls[index] })))
  //                   )
  //                 )
  //               ),
  //             isHaveRejectReason
  //               ? this.apiService.searchStatusHistories({
  //                   orderBy: 'createdAt',
  //                   resaleIds: id,
  //                 })
  //               : of(null),
  //           ]);
  //         }),
  //         switchMap(([item, billing, documents, statusHistoriesPaginated]) => {
  //           item = ResaleTransactionModel.merge(item, {
  //             rejectReason: statusHistoriesPaginated?.results[0]?.data?.reason ?? null,
  //             billing: ResaleBillingModel.merge(billing, { companyProfit: item.billing.companyProfit }),
  //             documents,
  //           } as Partial<IResaleTransaction>);
  //           return of(ResaleTransactionAction.setItemDetail({ item }));
  //         })
  //       )
  //     )
  //   )
  // );

  loadPublicLinkDocument$ = createEffect(() => this.actions$.pipe(ofType(ResaleTransactionAction.loadPublicLinkDocument)));

  saveDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.saveDraftItem),
      concatLatestFrom(() => [this.store.select(ResaleTransactionSelector.selectDraftState), this.store.select(PersonalProfileSelector.selectPersonal)]),
      mergeMap(([_, { draftItem, draftType }, user]) => {
        const main = draftItem.relatedAgent?.mains[0];
        if (user && user?.id !== main?.agentId) {
          draftItem.relatedAgent.mains[0] = {
            ...main,
            agentId: user?.id,
            brokerName: user?.nricName?.toUpperCase() ?? '',
            brokerIdNo: user?.ceaRegNo?.toUpperCase()?.trim() ?? '',
            brokerPhone: user?.mobile1 as PhoneNumber,
            brokerEmail: user?.email1 ?? '',
          }
        }
        return iif(() => draftType === 'create' || draftType === 'clone', this.apiService.createFullData(draftItem), this.apiService.updateFullData(draftItem)).pipe(
          switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
        )
      }
      )
    )
  );

  submitDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.submitDraftItem),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftState)),
      mergeMap(([_, { draftItem }]) =>
        this.apiService.submitMultiple([{ id: draftItem.id, data: {}, metadata: { submitLateReason: draftItem.metadata?.submitLateReason } ?? {} }]).pipe(
          switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
        )
      )
    )
  );

  saveAndSubmitDraftItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.saveAndSubmitDraftItem),
      concatLatestFrom(() => [this.store.select(ResaleTransactionSelector.selectDraftState), this.store.select(PersonalProfileSelector.selectPersonal)]),
      mergeMap(([_, { draftItem, draftType }, user]) => {
        const main = draftItem.relatedAgent.mains[0];
        if (user && user?.id !== main?.agentId) {
          draftItem.relatedAgent.mains[0] = {
            ...main,
            agentId: user?.id,
            brokerName: user?.nricName?.toUpperCase() ?? '',
            brokerIdNo: user?.ceaRegNo?.toUpperCase()?.trim() ?? '',
            brokerPhone: user?.mobile1 as PhoneNumber,
            brokerEmail: user?.email1 ?? '',
          }
        }
        return iif(() => draftType === 'create' || draftType === 'clone', this.apiService.createFullData(draftItem), this.apiService.updateSubmitFullData(draftItem)).pipe(
          switchMap(id => {
            return this.apiService.submitMultiple([{ id, data: {}, metadata: { submitLateReason: draftItem.metadata?.submitLateReason } ?? {} }]);
          }),
          switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
        )
      }
      )
    )
  );

  // submitWithReasonForSubmitLate$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ResaleTransactionAction.submitWithReasonForSubmitLate),
  //     concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftItem)),
  //     mergeMap(([_, draftItem]) => {
  //       const dto = ResaleTransactionUpdateDto.fromJson({
  //         group: 'full',
  //         data: {
  //           metadata: draftItem.metadata,
  //         },
  //       });
  //       return this.apiService.update(draftItem.id, dto).pipe(
  //         switchMap(id => {
  //           return this.apiService.submit([id]).pipe(
  //             mapTo({
  //               id,
  //               status: EBlankFormStatus.submitted,
  //             })
  //           );
  //         }),
  //         switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
  //         catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
  //       );
  //     })
  //   )
  // );

  loadGrossCommmPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.loadGrossCommPreview),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftItem)),
      switchMap(([_, draftItem]) => {
        const isCobroke = draftItem.metadata?.isCobroke ?? false;
        if (!isCobroke) {
          const main = ResaleCoBrokeModel.merge(draftItem.relatedAgent.mains[0], { receivedPercent: 100 });
          const relatedAgent = ResaleRelatedAgentModel.merge(draftItem.relatedAgent, { mains: [main], internals: [], externals: [] });
          draftItem = ResaleTransactionModel.merge(draftItem, { relatedAgent });
        }

        const dto = ResaleTransactionPreviewDto.fromJson(draftItem);
        if (dto.relatedAgent.externals.length > 0) {
          dto.relatedAgent.externals.forEach(item => {
            if (dto.billing?.gstPercent) {
              item.grossComm.taxValue = dto.billing?.gstPercent;
            }
          })
        }

        return this.apiService.preview(dto).pipe(
          switchMap(res => {
            let { externals, mains, internals } = res.relatedAgent;
            mains = mains.map((item, index) => ResaleCoBrokeModel.merge(draftItem.relatedAgent.mains[index], omit(item, ['agentId', 'agent', 'uniqueId'])));
            internals = internals.map((item, index) =>
              ResaleCoBrokeModel.merge(draftItem.relatedAgent.internals[index], omit(item, ['agentId', 'agent', 'uniqueId']))
            );
            externals = externals.map((item, index) =>
              ResaleExternalCoBrokeModel.merge(draftItem.relatedAgent.externals[index], omit(item, ['agentId', 'agent', 'uniqueId']))
            );

            return of(
              ResaleTransactionAction.updateBillingDraftItem({ data: res.billing }),
              ResaleTransactionAction.updateDraftItem({
                data: {
                  relatedAgent: ResaleRelatedAgentModel.fromJson({
                    mains,
                    internals,
                    externals,
                  }),
                },
              })
            );
          }),
          catchError(error => of(ResaleTransactionAction.loadGrossCommPreviewFail({ error })))
        );
      })
    )
  );

  loadPublicLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.addDocumentsDraftItem),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDocumentsDraftItem)),
      mergeMap(([_, items]) =>
        forkJoin(items.map(item => iif(() => !item.publicLink, this.storageApi.getPublicLink({ keyName: item.url }), of(item.publicLink)))).pipe(
          switchMap(urls =>
            of(
              ResaleTransactionAction.updateDraftItem({
                data: { documents: items.map((item, index) => ResaleDocumentModel.merge(item, { publicLink: urls[index] })) },
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store, private apiService: ResaleTransactionApiService, private storageApi: TransactionStorageApiService) { }
}
