// core
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// share
import { RecruitmentApiService, TemplateApiService } from '@agent-portal/apis';
import { RecruitmentApplicationAction } from '../actions';
import { RecruitmentApplicationFacade } from '../facades';
import { ROUTE } from '@agent-portal/constants';

@Injectable()
export class RecruitmentApplicationEffect {
  loadRecruitment$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(RecruitmentApplicationAction.loadRecruitment),
      exhaustMap(action => {
        return this._recruitmentApiService.getPublic(action.uuid).pipe(
          map(recruitment => {
            this._recruitmentApplicationFacade.getTemplateById([
              recruitment.protectionTemplateId ?? 1,
              recruitment.authorisationTemplateId ?? 2,
              recruitment.agreementTemplateId ?? 3,
            ]);
            return RecruitmentApplicationAction.loadRecruitmentSuccess({ recruitment });
          }),
          catchError(({ error }) => {
            switch (error?.errorCode) {
              case 1001:
                this._router.navigate([ROUTE.PUBLIC, ROUTE.RECRUITMENT.PATH, ROUTE.RECRUITMENT.ERROR]);
                break;
              case 1002:
                this._router.navigate([ROUTE.PUBLIC, ROUTE.RECRUITMENT.PATH, ROUTE.RECRUITMENT.COMPLETED]);
                break;
              default:
                this._router.navigate([ROUTE.PUBLIC, ROUTE.RECRUITMENT.PATH, ROUTE.RECRUITMENT.ERROR]);
                break;
            }

            return of(RecruitmentApplicationAction.loadRecruitmentFailure({ error }));
          })
        );
      })
    );
  });

  loadTemplates$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(RecruitmentApplicationAction.loadTemplate),
      exhaustMap(action => {
        return this._templateApiService.searchPublic({ ids: action.ids }).pipe(
          map(({ results }) => {
            return RecruitmentApplicationAction.loadTemplateSuccess({ templates: results });
          })
        );
      })
    );
  });

  constructor(
    private readonly _router: Router,
    private readonly _actions$: Actions,
    private readonly _templateApiService: TemplateApiService,
    private readonly _recruitmentApiService: RecruitmentApiService,
    private readonly _recruitmentApplicationFacade: RecruitmentApplicationFacade
  ) { }
}
