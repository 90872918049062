import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { BlankFormAction } from '../actions';
import { BlankFormSelector } from '../selectors';
import {
  BlankFormIcModel,
  BlankFormInternalCoBrokeModel,
  BlankFormModel,
  BlankFormRelatedAgentModel,
  SplitMatrixModel,
  SplitPartModel,
} from '@shared/data-access/models';
import { EBlankFormStatus } from '@shared/data-access/enums';
import { isNil, omitBy, uniqBy } from 'lodash-es';
import { BlankFormApiService, KeyAppointmentApiService, ProjectTeamMemberApiService, UnitApiService } from '@agent-portal/apis';
import { BlankFormCreateDto, BlankFormUpdateDto, UnitUpdateDto } from '@shared/data-access/dto';
import { DateTimeConverter } from '@shared/utils/datetime-converter';

@Injectable()
export class BlankFormEffects {
  loadDefaultDataFromProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.loadDefaultDataFromProject),
      mergeMap(({ projectId, optionDate }) => {
        const optionDateDto = DateTimeConverter.formatISO(optionDate ? new Date(optionDate) : new Date(), { hours: 0, minutes: 0, seconds: 0 });
        return forkJoin([
          optionDateDto ? this.projectTeamMemberApiService.getTeamLeaders({
            projectId,
            getTiers: true,
            optionDate: optionDateDto,
          }) : this.projectTeamMemberApiService.getTeamLeaders({
            projectId,
            getTiers: true,
          }),
          this.keyAppointmentApiService.search({ projectIds: projectId, optionDate: optionDateDto, getTiers: true }),
        ]).pipe(
          switchMap(([teamLeaders, keyAppointmentsPaginated]) => {
            const leadersDefault = teamLeaders.reduce((acc, curr) => {
              const { splitMatrices } = curr.salesperson.commissionScheme;
              const defaultSplitMatrices = splitMatrices.map(({ tiers, parties }) => {
                tiers = tiers.map(tier => SplitPartModel.merge(tier, { value: tier.salespersonId ? 0 : null }));
                parties = parties.map(party => SplitPartModel.merge(party, { value: party.salespersonId ? 0 : null }));
                return BlankFormInternalCoBrokeModel.fromJson({
                  agentId: tiers[0].salespersonId,
                  agent: tiers[0].salesperson,
                  tiers,
                  parties,
                });
              });
              return [...acc, ...defaultSplitMatrices];
            }, [] as BlankFormInternalCoBrokeModel[]);
            const leaders = uniqBy(leadersDefault, 'agentId');
            const bonuses = keyAppointmentsPaginated.results.map(keyAppointment => {
              const { salesperson, salespersonId, appointmentType, appointmentTypeId } = keyAppointment;
              const splitMatrixDefault = SplitMatrixModel.createEmpty();
              splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
                level: 1,
                salespersonId,
                salesperson,
                value: 0,
              });
              const { tiers, parties } = splitMatrixDefault;
              return BlankFormIcModel.fromJson({
                tiers,
                parties,
                salespersonId,
                salesperson,
                appointmentType,
                appointmentTypeId,
              });
            });
            const overridings = [...bonuses];
            return of(
              BlankFormAction.updateDraftItem({
                data: {
                  relatedAgent: BlankFormRelatedAgentModel.merge(BlankFormRelatedAgentModel.createEmpty(), {
                    leaders,
                  }),
                  bonuses,
                  overridings,
                },
              }),
              BlankFormAction.setLoadingStatus({ loading: false })
            );
          })
        );
      })
    )
  );

  saveDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.saveDraftItem),
      concatLatestFrom(() => this.store.select(BlankFormSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) => {
        let apiCall$: Observable<any>;
        let payload: BlankFormCreateDto | BlankFormUpdateDto;

        const dataJson = BlankFormModel.toJson(draftItem);
        dataJson['bds'] = null;
        dataJson['bonuses'] = null;
        dataJson['overridings'] = null;
        dataJson['pools'] = null;
        dataJson['pds'] = null;
        dataJson['referrals'] = null;
        dataJson['otherFees'] = null;
        dataJson['buyers'] = null;
        dataJson['buyer'] = null;
        const { unitId, unit, unitBlkNo, unitPostalCode, unitNoOfRoom, landAreaSqft, landAreaSqm } = draftItem;
        const isUnitInfoChange = unitBlkNo !== unit?.blkNo || unitPostalCode !== unit?.postalCode || unitNoOfRoom !== unit?.noOfRoom || landAreaSqft !== unit?.areaSqft || landAreaSqm !== unit?.areaSqm;
        const unitPayload = UnitUpdateDto.fromJson({
          ...omitBy(unit, isNil),
          blkNo: unitBlkNo,
          postalCode: unitPostalCode,
          noOfRoom: unitNoOfRoom,
          areaSqft: landAreaSqft,
          areaSqm: landAreaSqm
        });

        if (draftType === 'create') {
          payload = BlankFormCreateDto.fromJson(dataJson);
          apiCall$ = forkJoin([this.apiService.create(payload), isUnitInfoChange ? this._unitApiService.update(unitId, unitPayload) : of(null)]);
        } else {
          const payload = BlankFormUpdateDto.fromJson({
            group: 'full',
            data: dataJson,
          });
          apiCall$ = forkJoin([this.apiService.update(draftItem.id, payload), isUnitInfoChange ? this._unitApiService.update(unitId, unitPayload) : of(null)]);
        }
        return apiCall$.pipe(
          switchMap(([res, _]) => of(BlankFormAction.submitItemSuccess({ res }))),
          catchError(error => of(BlankFormAction.submitItemFail({ error })))
        );
      })
    )
  );

  saveAndSubmitDraftItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.saveAndSubmitDraftItem),
      concatLatestFrom(() => this.store.select(BlankFormSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) => {
        let apiCall$: Observable<any>;
        let payload: BlankFormCreateDto | BlankFormUpdateDto;
        const dataJson = BlankFormModel.toJson(draftItem);
        dataJson['bds'] = null;
        dataJson['bonuses'] = null;
        dataJson['overridings'] = null;
        dataJson['pools'] = null;
        dataJson['pds'] = null;
        dataJson['referrals'] = null;
        dataJson['otherFees'] = null;
        dataJson['buyers'] = null;
        dataJson['buyer'] = null;
        const { unitId, unit, unitBlkNo, unitPostalCode, unitNoOfRoom, landAreaSqft, landAreaSqm } = draftItem;
        const isUnitInfoChange = unitBlkNo !== unit?.blkNo || unitPostalCode !== unit?.postalCode || unitNoOfRoom !== unit?.noOfRoom || landAreaSqft !== unit?.areaSqft || landAreaSqm !== unit?.areaSqm;
        const unitPayload = UnitUpdateDto.fromJson({
          ...omitBy(unit, isNil),
          blkNo: unitBlkNo,
          postalCode: unitPostalCode,
          noOfRoom: unitNoOfRoom,
          areaSqft: landAreaSqft,
          areaSqm: landAreaSqm
        });

        if (draftType === 'create') {
          payload = BlankFormCreateDto.fromJson(dataJson);
          apiCall$ = forkJoin([
            this.apiService.create(payload).pipe(map(({ id }) => id)),
            isUnitInfoChange ? this._unitApiService.update(unitId, unitPayload) : of(null),
          ]);
        } else {
          payload = BlankFormUpdateDto.fromJson({
            group: 'full',
            data: dataJson,
          });
          apiCall$ = forkJoin([
            this.apiService.update(draftItem.id, payload).pipe(map(() => draftItem.id)),
            isUnitInfoChange ? this._unitApiService.update(unitId, unitPayload) : of(null),
          ]);
        }
        return apiCall$.pipe(
          switchMap(([id, _]) => {
            return this.apiService.submit(id).pipe(
              map(() => ({
                id,
                status: EBlankFormStatus.submitted,
                blankFormType: draftItem.blankFormType,
              }))
            );
          }),
          switchMap(res => of(BlankFormAction.submitItemSuccess({ res }))),
          catchError(error => of(BlankFormAction.submitItemFail({ error })))
        );
      })
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private _unitApiService: UnitApiService,
    private apiService: BlankFormApiService,
    private projectTeamMemberApiService: ProjectTeamMemberApiService,
    private keyAppointmentApiService: KeyAppointmentApiService
  ) { }
}
