import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { AgentProjectTransactionModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[AgentProjectTransaction] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[AgentProjectTransaction] Load items success', props<{ data: PaginationAdapter<AgentProjectTransactionModel> }>());

// item
export const loadItem = createAction('[AgentProjectTransaction] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[AgentProjectTransaction] Load item success', props<{ item: AgentProjectTransactionModel }>());

// agreement date
export const setAgreementDate = createAction('[AgentProjectTransaction] SET AGREEMENT DATE', props<{ date: Date }>());

// Leaders
// export const setMainLeadersFromTeamMembers = createAction(
//   '[AgentProjectTransaction] SET MAIN LEADERS FROM TEAM MEMBERS',
//   props<{ teamMembers: ProjectTeamMemberModel[] }>()
// );

// export const setMainLeaders = createAction('[AgentProjectTransaction] SET MAIN LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

// export const clearMainLeaders = createAction('[AgentProjectTransaction] CLEAR MAIN LEADERS');

// export const addCobrokeLeadersFromTeamMembers = createAction(
//   '[AgentProjectTransaction] ADD LEADERS FROM TEAM MEMBERS',
//   props<{ teamMembers: ProjectTeamMemberModel[] }>()
// );

// export const setCobrokeLeaders = createAction('[AgentProjectTransaction] SET COBROKE LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

// export const removeCobrokeLeaders = createAction('[AgentProjectTransaction] REMOVE LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

// export const clearCobrokerLeaders = createAction('[AgentProjectTransaction] CLEAR COBROKE LEADERS');
