import { createAction, props } from '@ngrx/store';
import { EcddChecklistModel, EcddCustomerModel, EcddModel, EcddOptionGroupModel } from '@shared/data-access/models';
import { EEcddType } from '@shared/data-access/enums';

export const setItemDetail = createAction('[ECDD] SET ITEM DETAIL', props<{ item: EcddModel | null }>());

export const loadItemDetail = createAction('[ECDD] LOAD ITEM DETAIL', props<{ id: number }>());

export const updateItemDetail = createAction('[ECDD] UPDATE ITEM DETAIL', props<{ data: Partial<EcddModel> }>());

export const setDraftType = createAction('[ECDD] SET TYPE', props<{ draftType: 'create' | 'edit' }>());

export const setDraftItem = createAction('[ECDD] SET DRAFT ITEM', props<{ item: EcddModel }>());

export const updateDraftItem = createAction('[ECDD] UPDATE DRAFT ITEM', props<{ data: Partial<EcddModel> }>());

export const saveDraftItem = createAction('[ECDD] SAVE DRAFT ITEM');

export const saveAndSubmitDraftItem = createAction('[ECDD] SAVE AND SUBMIT DRAFT ITEM');

export const submitItemSuccess = createAction('[ECDD] SUBMIT DRAFT ITEM SUCCESS', props<{ res: any }>());

export const submitItemFail = createAction('[ECDD] SUBMIT DRAFT ITEM FAIL', props<{ error: any }>());

export const resetDraftItem = createAction('[ECDD] RESET DRAFT ITEM');

export const resetSubmittingStatus = createAction('[ECDD] RESET SUBMITTING STATUS');

export const setEcddType = createAction('[ECDD] SET ECDD TYPE', props<{ ecddType: EEcddType }>());

export const setCustomer = createAction('[ECDD] SET CUSTOMER', props<{ customer: EcddCustomerModel | null }>());

export const updateCustomer = createAction('[ECDD] UPDATE CUSTOMER', props<{ data: Partial<EcddCustomerModel> }>());

export const initDraftState = createAction(
  '[ECDD] INIT DRAFT STATE',
  props<{ draftType: 'create' | 'edit'; draftItem: EcddModel; ecddType: EEcddType; isPublic: boolean }>()
);

export const setCustomerSignatureData = createAction('[ECDD] SET CUSTOMER SIGNATURE DATA', props<{ data: any }>());

export const resetState = createAction('[ECDD] RESET STATE');

export const saveAndCreateUrlForCustomer = createAction('[ECDD] SAVE AND CREATE URL FOR CUSTOMER');

export const saveAndProceedToChecklist = createAction('[ECDD] SAVE AND PROCEED TO CHECKLIST');

export const setOptionGroupsFromChecklist = createAction('[ECDD] SET OPTION GROUPS FROM CHECKLIST', props<{ checklist: EcddChecklistModel }>());

export const setOptionGroups = createAction('[ECDD] SET OPTION GROUPS', props<{ optionGroups: EcddOptionGroupModel[] }>());

export const setResSignatureData = createAction('[ECDD] SET RES SIGNATURE DATA', props<{ data: any }>());

export const savePublicDraftItem = createAction('[ECDD] SAVE PUBLIC DRAFT ITEM');

export const setPrerequisiteIdsActive = createAction('[ECDD] SET PREREQUISITE IDS ACTIVE', props<{ prerequisiteIdsActive: number[] }>());

export const setAppearIdsActive = createAction('[ECDD] SET APPEAR IDS ACTIVE', props<{ appearIdsActive: number[] }>());
