export * as RecruitmentReducer from './recruitment.reducers';
export * as ProjectSetupReducer from './project-setup.reducers';
export * as RecruitmentApplicationReducer from './recruitment-application.reducers';

export * as KeyAppointmentReducer from './key-appointment.reducers';

export * as CommissionMatrixReducer from './commission-matrix.reducers';

export * as PersonalProfileReducer from './personal-profile.reducers';

export * as ProjectConsultantReducer from './project-consultant.reducers';

export * as ProjectTransactionReducer from './project-transaction.reducers';

export * as ResaleTransactionReducer from './resale-transaction.reducer';

export * as BlankFormReducer from './blank-form.reducer';

export * as PhoneNumberReducer from './phone-number.reducers';

export * as EcddReducer from './ecdd.reducer';

export * as TeamOrgChartReducer from './team-org-chart.reducers';

export * as ReportReducer from './report.reducers';

export * as AgentBlankFormReducer from './agent-blank-form.reducer'

export * as AgentProjectTransactionReducer from './agent-project-transaction.reducer'