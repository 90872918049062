import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BlankFormAction } from '../actions';
import { BlankFormSelector } from '../selectors';
import { BlankFormModel } from '@shared/data-access/models';

@Injectable({
  providedIn: 'root',
})
export class BlankFormFacade {
  selectedItem$ = this._store.select(BlankFormSelector.selectItemDetail);
  selectedSubmittingStatus$ = this._store.select(BlankFormSelector.selectSubmittingStatus);
  selectedDraftItem$ = this._store.select(BlankFormSelector.selectDraftItem);
  selectedDraftType$ = this._store.select(BlankFormSelector.selectDraftType);
  selectedDraftState$ = this._store.select(BlankFormSelector.selectDraftState);

  constructor(private _store: Store) { }

  setItemDetail(item: BlankFormModel | null) {
    this._store.dispatch(BlankFormAction.setItemDetail({ item }));
  }

  loadItemDetail(id: number) {
    this._store.dispatch(BlankFormAction.loadItemDetail({ id }));
  }

  updateItemDetail(data: Partial<BlankFormModel>) {
    this._store.dispatch(BlankFormAction.updateItemDetail({ data }));
  }

  setDraftItemAsCreate() {
    this._store.dispatch(BlankFormAction.setDraftItemAsCreate());
  }

  setDraftItemAsEdit() {
    this._store.dispatch(BlankFormAction.setDraftItemAsEdit());
  }

  updateDraftItem(data: Partial<BlankFormModel>) {
    this._store.dispatch(BlankFormAction.updateDraftItem({ data }));
  }

  saveDraftItem() {
    this._store.dispatch(BlankFormAction.saveDraftItem());
  }

  saveAndSubmitDraftItem() {
    this._store.dispatch(BlankFormAction.saveAndSubmitDraftItem());
  }

  submitItemSuccess(res: any) {
    this._store.dispatch(BlankFormAction.submitItemSuccess({ res }));
  }

  submitItemFail(error: any) {
    this._store.dispatch(BlankFormAction.submitItemFail({ error }));
  }

  resetDraftItem() {
    this._store.dispatch(BlankFormAction.resetDraftItem());
  }

  resetSubmittingStatus() {
    this._store.dispatch(BlankFormAction.resetSubmittingStatus());
  }

  loadDefaultDataFromProject(projectId: number, optionDate: string) {
    this._store.dispatch(BlankFormAction.loadDefaultDataFromProject({ projectId, optionDate }));
  }

  setLoadingStatus(loading: boolean) {
    this._store.dispatch(BlankFormAction.setLoadingStatus({ loading }));
  }
}
