import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DummyComponent } from "./dummy.component";

const routes: Routes = [
    {
        path: '',
        component: DummyComponent,
    }
]
export const DummyRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);