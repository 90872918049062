<!-- Button -->
 <div class="flex">
  <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
      <img class="w-10 h-10 object-cover rounded-full" *ngIf="showAvatar && user?.avatar" [redLazyImage]="user?.avatar" alt="Contact avatar" />
      <mat-icon class="text-theme-gray" *ngIf="!showAvatar || !user?.avatar" svgIcon="user_circle"></mat-icon>
      <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full bg-green-500" [ngClass]="{ 'mr-px mb-px': !showAvatar || !user?.avatar }"></span>
    </span>
  </button>
  
  <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <ng-container #itemContainer></ng-container>
  </mat-menu>
 </div>

