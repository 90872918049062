// core
import { createAction, props } from '@ngrx/store';

// share
import { PersonalParticularModel } from '@shared/data-access/models';
import { FileUploaderModel } from '@red/data-access';

// division
export const loadDivision = createAction('[PersonalProfile] Load division');
export const loadDivisionSuccess = createAction('[PersonalProfile] Load division success', props<{ division: PersonalParticularModel | null }>());

// particular
export const loadParticular = createAction('[PersonalProfile] Load particular');
export const loadParticularSuccess = createAction('[PersonalProfile] Load particular success', props<{ particular: PersonalParticularModel }>());

// documents
export const loadDocuments = createAction('[PersonalProfile] Load documents');
export const loadDocumentsSuccess = createAction('[PersonalProfile] Load documents success', props<{ documents: FileUploaderModel[] }>());
