import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { KeyAppointmentModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[KeyAppointment] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[KeyAppointment] Load items success', props<{ data: PaginationAdapter<KeyAppointmentModel> }>());

// item
export const loadItem = createAction('[KeyAppointment] Load item', props<{ id: number }>());
export const loadMainIc = createAction('[KeyAppointment] Load main ic', props<{ projectId: number }>());
export const loadItemSuccess = createAction('[KeyAppointment] Load item success', props<{ item: KeyAppointmentModel }>());
