// core
import { createFeatureSelector, createSelector } from '@ngrx/store';

// share
import { RecruitmentApplicationReducer } from '../reducers';

const selectFeature = createFeatureSelector<RecruitmentApplicationReducer.RecruitmentApplicationState>(RecruitmentApplicationReducer.featureName);

export const selectDetail = createSelector(selectFeature, ({ detail }) => detail);
export const selectRecruitment = createSelector(selectFeature, ({ recruitment }) => recruitment);
export const selectTemplates = createSelector(selectFeature, ({ templates }) => templates);
export const selectPersonal = createSelector(selectFeature, ({ personal }) => personal);
export const selectAddress = createSelector(selectFeature, ({ address }) => address);
export const selectPostal = createSelector(selectFeature, ({ postal }) => postal);
export const selectState = createSelector(selectFeature, state => state);
