<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<red-empty-layout *ngIf="layout === 'empty'"></red-empty-layout>
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Classic layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<red-classic-layout *ngIf="layout === 'classic'">
  <ng-template redGroupActionHeader>
    <!-- <button
      class="red-mat-button-medium w-full !bg-[#723bd4] !text-white"
      mat-button
      (click)="loginToRedoc()"
    >
      <div class="w-5 mr-2">
        <img src="assets/images/brand/redoc_white.svg" />
      </div>
      <span> <span class="hidden sm:inline-block">Create digital contract with </span> Redoc </span>
    </button> -->

    <a class="w-full red-mat-button-medium !bg-theme-line !text-theme-dark" mat-button [href]="linkToHip" target="_blank">
      <div class="w-5 mr-2">
        <img src="assets/images/brand/logo_hip_compact.svg" />
      </div>
      <span>Back to HiP</span>
    </a>

    <red-user>
      <ng-template redUserMenuItem let-user>
        <button mat-menu-item>
          <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium truncate" [matTooltip]="user?.name ?? ''">{{ user?.name }}</span>
          </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="profile()">
          <mat-icon svgIcon="user_circle"></mat-icon>
          <span>Profile</span>
        </button>
        <!-- <button mat-menu-item>
          <mat-icon svgIcon="cog"></mat-icon>
          <span>Settings</span>
        </button> -->
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
          <mat-icon svgIcon="logout"></mat-icon>
          <span>Sign out</span>
        </button>
      </ng-template>
    </red-user>
  </ng-template>
</red-classic-layout>
