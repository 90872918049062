import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AgentBlankFormModel
} from '@shared/data-access/models';
import { AgentBlankFormAction } from '../actions';

export const featureName = 'agentBlankForm';

export interface BlankFormState {
  item: AgentBlankFormModel | null;
  draftItem: AgentBlankFormModel;
}

export const initialState: BlankFormState = {
  item: null,
  draftItem: AgentBlankFormModel.createEmpty(),
};

export const agentBlankFormFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(AgentBlankFormAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = agentBlankFormFeature;
