import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { BatchingApprovalCreateDto, BatchingApprovalTaskSubmittedDataDto, BatchingApprovalUpdateDto } from '@shared/data-access/dto';
import { IBatchingApproval } from '@shared/data-access/interfaces';
import { BatchingApprovalModel, BatchSearchEngineModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BatchingApprovalApiService {
  static ROOT_POINT = 'transaction/batches';
  static SUBMIT_TASK = 'transaction/batches/submit-task';
  static BY_INVOICE = 'transaction/batches/by-invoice';
  static SEARCH_ENGINE = 'transaction/engine/batches';
  static SEARCH_ENGINE_FINANCE = 'transaction/engine/finance/batches';
  static SEARCH_ENGINE_BLANK_FORM = 'transaction/engine/blank-form-batches';
  static SEARCH_ENGINE_FINANCE_BLANK_FORM = 'transaction/engine/finance/blank-form-batches';
  static ROOT_POINT_COMMON = 'transaction/batches/common';
  static ROOT_POINT_BF_COMMON = 'transaction/batches/blank-form/common';

  constructor(private apiService: ApiService) { }

  create(data: BatchingApprovalCreateDto): Observable<BatchingApprovalModel> {
    return this.apiService
      .post(`${BatchingApprovalApiService.ROOT_POINT}`, data)
      .pipe(map((res: IBatchingApproval) => BatchingApprovalModel.fromJson(res) as BatchingApprovalModel));
  }

  get(itemId: number): Observable<BatchingApprovalModel> {
    return this.apiService.get(BatchingApprovalApiService.ROOT_POINT + '/' + itemId).pipe(map(res => BatchingApprovalModel.fromJson(res) as BatchingApprovalModel));
  }

  update(id: number, data: BatchingApprovalUpdateDto): Observable<any> {
    return this.apiService.patch(BatchingApprovalApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<BatchingApprovalModel>> {
    return this.apiService.get(BatchingApprovalApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(BatchingApprovalModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(BatchingApprovalApiService.ROOT_POINT, { id }, option);
  }

  submitTask(payload: BatchingApprovalTaskSubmittedDataDto): Observable<any> {
    return this.apiService.post(BatchingApprovalApiService.SUBMIT_TASK, payload, { excludeFields: [''] });
  }

  getByInvoice(invoiceId: number, query = {}, option?: IApiOption) {
    return this.apiService.get(BatchingApprovalApiService.BY_INVOICE + '/' + invoiceId, query, option).pipe(map(data => BatchingApprovalModel.fromJson(data)));
  }

  searchEngine(query = {}, option?: IApiOption): Observable<PaginationAdapter<BatchSearchEngineModel>> {
    return this.apiService.get(BatchingApprovalApiService.SEARCH_ENGINE, query, option).pipe(map(data => new PaginationAdapter(BatchSearchEngineModel, data)));
  }

  searchEngineFinance(query = {}, option?: IApiOption): Observable<PaginationAdapter<BatchSearchEngineModel>> {
    return this.apiService.get(BatchingApprovalApiService.SEARCH_ENGINE_FINANCE, query, option).pipe(map(data => new PaginationAdapter(BatchSearchEngineModel, data)));
  }

  searchEngineById(id: number, query = {}, option?: IApiOption): Observable<BatchingApprovalModel> {
    return this.apiService.get(BatchingApprovalApiService.SEARCH_ENGINE + '/' + id, query, option).pipe(map(data => BatchingApprovalModel.fromJson(data)));
  }

  getProformaCode(id: number, query = {}, option?: IApiOption): Observable<any> {
    return this.apiService.get(BatchingApprovalApiService.ROOT_POINT + '/' + id + '/proforma-code', query, option);
  }

  searchEngineBlankFormBatch(query = {}, option?: IApiOption): Observable<PaginationAdapter<BatchSearchEngineModel>> {
    return this.apiService.get(BatchingApprovalApiService.SEARCH_ENGINE_BLANK_FORM, query, option).pipe(map(data => new PaginationAdapter(BatchSearchEngineModel, data)));
  }

  searchEngineFinanceBlankFormBatch(query = {}, option?: IApiOption): Observable<PaginationAdapter<BatchSearchEngineModel>> {
    return this.apiService.get(BatchingApprovalApiService.SEARCH_ENGINE_FINANCE_BLANK_FORM, query, option).pipe(map(data => new PaginationAdapter(BatchSearchEngineModel, data)));
  }

  searchEngineBlankFormBatchById(id: number, query = {}, option?: IApiOption): Observable<BatchingApprovalModel> {
    return this.apiService.get(BatchingApprovalApiService.SEARCH_ENGINE_BLANK_FORM + '/' + id, query, option).pipe(map(data => BatchingApprovalModel.fromJson(data)));
  }

  getCommon(itemId: number): Observable<BatchingApprovalModel> {
    return this.apiService.get(BatchingApprovalApiService.ROOT_POINT_COMMON + '/' + itemId).pipe(map(res => BatchingApprovalModel.fromJson(res) as BatchingApprovalModel));
  }

  getBfCommon(itemId: number): Observable<BatchingApprovalModel> {
    return this.apiService.get(BatchingApprovalApiService.ROOT_POINT_BF_COMMON + '/' + itemId).pipe(map(res => BatchingApprovalModel.fromJson(res) as BatchingApprovalModel));
  }
}
