import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { PersonalProfileAction } from '../actions';
import { PersonalProfileReducer } from '../reducers';
import { PersonalProfileSelector } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class PersonalProfileFacade {
  selectedItem$ = this._store.select(PersonalProfileSelector.selectPersonal);
  selectedDivision$ = this._store.select(PersonalProfileSelector.selectDivision);
  selectedDocuments$ = this._store.select(PersonalProfileSelector.selectDocuments);

  constructor(private readonly _store: Store<PersonalProfileReducer.PersonalProfileState>) {}

  getPersonalProfile(): void {
    this._store.dispatch(PersonalProfileAction.loadParticular());
  }

  getDivision(): void {
    this._store.dispatch(PersonalProfileAction.loadDivision());
  }

  getDocuments(): void {
    this._store.dispatch(PersonalProfileAction.loadDocuments());
  }
}
