import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LayoutBaseModule, UserModule } from '@shared/components/layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { ConfirmDialogModule } from '@shared/components/confirm-dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [LayoutComponent],
  imports: [CommonModule, LayoutBaseModule, UserModule, MatIconModule, MatButtonModule, MatDividerModule, MatMenuModule, ConfirmDialogModule, MatTooltipModule],
  exports: [LayoutComponent],
})
export class LayoutModule { }
