import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EcddReducer } from '../reducers';

export const selectEcddFeature = createFeatureSelector<EcddReducer.EcddState>(EcddReducer.featureName);

export const selectItemDetail = createSelector(selectEcddFeature, ({ item }) => item);

export const selectDraftItem = createSelector(selectEcddFeature, ({ draftItem }) => draftItem);

export const selectDraftType = createSelector(selectEcddFeature, ({ draftType }) => draftType);

export const selectEcddType = createSelector(selectEcddFeature, ({ ecddType }) => ecddType);

export const selectCustomer = createSelector(selectEcddFeature, ({ customer }) => customer);

export const selectCustomerSignatureData = createSelector(selectEcddFeature, ({ customerSignatureData }) => customerSignatureData);

export const selectSubmittingStatus = createSelector(selectEcddFeature, ({ submittingStatus }) => submittingStatus);

export const selectSubmittingLoading = createSelector(selectSubmittingStatus, ({ loading }) => loading);

export const selectIsCustomerSigned = createSelector(selectCustomer, customer => !!customer?.signature);

export const selectIsPublic = createSelector(selectEcddFeature, ({ isPublic }) => isPublic);

export const selectResSignatureData = createSelector(selectEcddFeature, ({ resSignatureData }) => resSignatureData);

export const selectDraftState = createSelector(
  selectDraftItem,
  selectDraftType,
  selectEcddType,
  selectIsCustomerSigned,
  selectCustomerSignatureData,
  selectCustomer,
  selectIsPublic,
  selectSubmittingStatus,
  selectResSignatureData,
  (draftItem, draftType, ecddType, isCustomerSigned, customerSignatureData, customer, isPublic, submittingStatus, resSignatureData) => ({
    draftItem,
    draftType,
    ecddType,
    isCustomerSigned,
    customerSignatureData,
    customer,
    isPublic,
    submittingStatus,
    resSignatureData,
  })
);

export const selectOptionGroups = createSelector(selectEcddFeature, ({ optionGroups: optionGroups }) => optionGroups);

export const selectOptionIdsPrerequisite = createSelector(selectEcddFeature, ({ optionIdsPrerequisite }) => optionIdsPrerequisite);

export const selectPrerequisiteIdsActive = createSelector(selectEcddFeature, ({ prerequisiteIdsActive }) => prerequisiteIdsActive);

export const selectOptionAppearIds = createSelector(selectEcddFeature, ({ optionAppearIds }) => optionAppearIds);

export const selectAppearIdsActive = createSelector(selectEcddFeature, ({ appearIdsActive }) => appearIdsActive);

export const selectIsSomePrerequisiteIdsActive = (ids: number[]) =>
  createSelector(selectPrerequisiteIdsActive, prerequisiteIds => ids.some(id => prerequisiteIds.includes(id)));

export const selectIsSomeAppearIdsActive = (ids: number[]) => createSelector(selectAppearIdsActive, appearIdsActive => ids.some(id => appearIdsActive.includes(id)));
