import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AgentBlankFormAction } from '../actions';
import { AgentBlankFormSelector } from '../selectors';
import { AgentBlankFormModel, BlankFormBdModel, BlankFormIcModel, BlankFormModel, BlankFormOtherFeeModel, BlankFormPdModel, BlankFormReferralModel } from '@shared/data-access/models';
import { IBlankFormRelatedAgent } from '@shared/data-access/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AgentBlankFormFacade {
  selectedItem$ = this._store.select(AgentBlankFormSelector.selectItemDetail);

  constructor(private _store: Store) { }

  setItemDetail(item: AgentBlankFormModel | null) {
    this._store.dispatch(AgentBlankFormAction.setItemDetail({ item }));
  }
  loadItemDetail(id: number) {
    this._store.dispatch(AgentBlankFormAction.loadItemDetail({ id }));
  }
}
