import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgentReportModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { ReportAction } from '../actions';
import { ReportReducer } from '../reducers';
import { ReportSelector } from '../selectors';

@Injectable()
export class ReportFacade {
  selectedItem$ = this._store.select(ReportSelector.selectReport);
  constructor(private _store: Store<ReportReducer.ReportState>) {}

  /**
   * Get @AgentReportModel by id
   */
  getById(id: number): Observable<AgentReportModel | null> {
    this._store.dispatch(ReportAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<AgentReportModel | null>;
  }
}
