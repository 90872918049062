import { createFeature, createReducer, on } from '@ngrx/store';
import { EEcddResponseType, EEcddType } from '@shared/data-access/enums';
import { IEcddOptionGroup } from '@shared/data-access/interfaces';
import { EcddCustomerModel, EcddModel, EcddOptionGroupModel } from '@shared/data-access/models';
import { flatten, intersection, isEqual, uniq, uniqWith } from 'lodash-es';
import { EcddAction } from '../actions';

export const featureName = 'ecdd';

export interface EcddState {
  item: EcddModel | null;
  draftItem: EcddModel;
  draftType: 'create' | 'edit';
  submittingStatus: {
    success:
      | any
      | {
          type: EEcddResponseType;
          [key: string]: any;
        };
    loading: boolean;
    error: any;
  };
  ecddType: EEcddType;
  isPublic: boolean;
  customer: EcddCustomerModel | null;
  customerSignatureData: any;
  resSignatureData: any;
  optionGroups: EcddOptionGroupModel[];
  optionIdsPrerequisite: { id: number; value: number[] }[];
  prerequisiteIdsActive: number[];
  optionAppearIds: number[];
  appearIdsActive: number[];
}

export const initialState: EcddState = {
  item: null,
  draftItem: EcddModel.createEmpty(),
  draftType: 'create',
  submittingStatus: {
    success: null,
    loading: false,
    error: null,
  },
  ecddType: EEcddType.Entity,
  isPublic: false,
  customer: null,
  customerSignatureData: null,
  resSignatureData: null,
  optionGroups: [],
  optionIdsPrerequisite: [],
  prerequisiteIdsActive: [],
  optionAppearIds: [],
  appearIdsActive: [],
};

export const ecddFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(EcddAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
    on(EcddAction.updateItemDetail, (state, { data }) => ({
      ...state,
      item: state.item ? EcddModel.merge(state.item, data) : null,
    })),
    on(EcddAction.setDraftType, (state, { draftType }) => ({
      ...state,
      draftType,
    })),
    on(EcddAction.setDraftItem, (state, { item }) => ({
      ...state,
      draftItem: item,
    })),
    on(EcddAction.updateDraftItem, (state, { data }) => ({
      ...state,
      draftItem: EcddModel.merge(state.draftItem, data),
    })),
    on(EcddAction.saveDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(EcddAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(EcddAction.saveAndCreateUrlForCustomer, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(EcddAction.saveAndProceedToChecklist, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(EcddAction.submitItemSuccess, (state, { res }) => ({
      ...state,
      submittingStatus: {
        success: res,
        loading: false,
        error: null,
      },
    })),
    on(EcddAction.submitItemFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error,
      },
    })),
    on(EcddAction.resetDraftItem, state => ({
      ...state,
      item: null,
      draftItem: EcddModel.createEmpty(),
      draftType: 'create' as 'create' | 'edit',
    })),
    on(EcddAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error: null,
      },
    })),
    on(EcddAction.setEcddType, (state, { ecddType }) => ({
      ...state,
      ecddType,
    })),
    on(EcddAction.updateCustomer, (state, { data }) => ({
      ...state,
      customer: EcddCustomerModel.merge((state.customer as EcddCustomerModel) ?? EcddCustomerModel.createEmpty(), data),
    })),
    on(EcddAction.initDraftState, (state, { draftType, draftItem, ecddType, isPublic }) => ({
      ...state,
      draftType,
      draftItem,
      ecddType,
      customer: draftItem.customers[0] || null,
      isPublic,
    })),
    on(EcddAction.setCustomerSignatureData, (state, { data }) => ({
      ...state,
      customerSignatureData: data,
    })),
    on(EcddAction.setOptionGroups, (state, { optionGroups }) => {
      const optionIdsPrerequisite = uniqWith(flatten(optionGroups.filter(o => o.optionGroup?.depends?.length).map(o => o.optionGroup.depends)), isEqual).map(
        (value, id) => ({ id, value })
      );
      const optionAppearIds = uniq(flatten(optionGroups.filter(o => o.optionGroup?.appear).map(o => o.optionGroup.appear)));
      const choosenOptionIds = optionGroups.filter(o => o.choosenOptionId).map(o => o.choosenOptionId);
      const prerequisiteIdsActive = optionIdsPrerequisite.filter(o => intersection(choosenOptionIds, o.value).length === o.value.length).map(o => o.id);
      const appearIdsActive = optionAppearIds.filter(id => choosenOptionIds.includes(id));
      optionGroups = optionGroups
        .map(data => {
          let plainData = EcddOptionGroupModel.toJson(data) as IEcddOptionGroup;
          const { choosenOptionId, choosenOption, optionGroup, remarks, sequence } = plainData;
          plainData = { ...plainData, ...optionGroup, optionGroupId: optionGroup?.id ?? 0, choosenOptionId, choosenOption, sequence, remarks };
          delete plainData.optionGroup;
          return EcddOptionGroupModel.fromJson(plainData);
        })
        .sort((a, b) => a.sequence - b.sequence)
        .reduce((acc, optionGroup) => {
          if (optionGroup.name && !optionGroup.parentId) {
            acc.push(optionGroup);
            return acc;
          }
          if (optionGroup.parentId) {
            const index = acc.findIndex(p => p.id === optionGroup.parentId);
            if (index !== -1) {
              acc[index] = EcddOptionGroupModel.merge(acc[index], {
                children: [...acc[index].children, optionGroup],
              });
              return acc;
            }
          }
          return acc;
        }, [] as EcddOptionGroupModel[]);

      return {
        ...state,
        optionGroups,
        optionIdsPrerequisite,
        prerequisiteIdsActive,
        optionAppearIds,
        appearIdsActive,
      };
    }),
    on(EcddAction.setResSignatureData, (state, { data }) => ({
      ...state,
      resSignatureData: data,
    })),
    on(EcddAction.savePublicDraftItem, state => ({
      ...state,
      submittingStatus: {
        loading: true,
        error: null,
        success: null,
      },
    })),
    on(EcddAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        loading: true,
        error: null,
        success: null,
      },
    })),
    on(EcddAction.resetState, () => initialState),
    on(EcddAction.setPrerequisiteIdsActive, (state, { prerequisiteIdsActive }) => ({
      ...state,
      prerequisiteIdsActive,
    })),
    on(EcddAction.setAppearIdsActive, (state, { appearIdsActive }) => ({
      ...state,
      appearIdsActive,
    }))
  ),
});

export const { name, reducer } = ecddFeature;
