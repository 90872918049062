import { createFeature, createReducer, on } from '@ngrx/store';
import { ProjectCommissionMatrixModel } from '@shared/data-access/models';
import { CommissionMatrixAction } from '../actions';

export const featureName = 'commissionMatrix';

export interface CommissionMatrixState {
  item: ProjectCommissionMatrixModel | null;
  agentInfo: ProjectCommissionMatrixModel | null;
  newItem: ProjectCommissionMatrixModel | null;
  updatedItem: ProjectCommissionMatrixModel | null;
  error: any;
}

export const initialState: CommissionMatrixState = {
  item: null,
  agentInfo: null,
  newItem: null,
  updatedItem: null,
  error: null,
};

export const commissionMatrixFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CommissionMatrixAction.loadItemSuccess, (state, prop: { item: ProjectCommissionMatrixModel }) => ({ ...state, item: prop.item })),

    // Get Agent Info
    on(CommissionMatrixAction.getAgentInfoSuccess, (state, prop: { item: ProjectCommissionMatrixModel }) => ({ ...state, agentInfo: prop.item })),
    on(CommissionMatrixAction.getAgentInfoFailed, (state, prop: { error: any }) => ({ ...state, error: prop.error })),
    on(CommissionMatrixAction.resetAgentInfo, state => ({ ...state, agentInfo: null })),

    // create item
    on(CommissionMatrixAction.createItemSuccess, (state, prop: { item: ProjectCommissionMatrixModel }) => ({ ...state, newItem: prop.item })),
    on(CommissionMatrixAction.createItemFailed, (state, prop: { error: any }) => ({ ...state, error: prop.error })),

    // update item
    on(CommissionMatrixAction.updateItemSuccess, (state, prop: { item: ProjectCommissionMatrixModel }) => ({ ...state, updatedItem: prop.item })),
    on(CommissionMatrixAction.updateItemFailed, (state, prop: { error: any }) => ({ ...state, error: prop.error })),

    // submit
    on(CommissionMatrixAction.submitItemSuccess, state => {
      return { ...state, newItem: ProjectCommissionMatrixModel.createEmpty(), updatedItem: ProjectCommissionMatrixModel.createEmpty() };
    }),
    on(CommissionMatrixAction.submitItemFailed, (state, prop: { error: any }) => ({ ...state, error: prop.error }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = commissionMatrixFeature;
