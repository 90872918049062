import { Injectable } from '@angular/core';
import { ProjectConsultantApiService, ProjectTeamMemberApiService } from '@agent-portal/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, mergeMap, of } from 'rxjs';
import { ProjectConsultantAction } from '../actions';

@Injectable()
export class ProjectConsultantEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectConsultantAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => ProjectConsultantAction.loadItemSuccess({ item })));
      })
    );
  });
  countMember$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectConsultantAction.countMember),
      mergeMap(action =>
        this.projectTeamMemberApiService.countMember(action.teamId, true).pipe(
          map(data => ProjectConsultantAction.countMemberSuccess(data)),
          catchError(error => of(ProjectConsultantAction.countMemberFailure({ error })))
        )
      )
    );
  });
  leader$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ProjectConsultantAction.getLeader),
      mergeMap(action =>
        this.projectTeamMemberApiService.getTeamLeader(action.teamId).pipe(
          map(data => ProjectConsultantAction.getLeaderSuccess({ teamId: action.teamId, leader: data })),
          catchError(error => of(ProjectConsultantAction.countMemberFailure({ error })))
        )
      )
    );
  });
  constructor(
    private _apiService: ProjectConsultantApiService,
    private projectTeamMemberApiService: ProjectTeamMemberApiService,
    private _actions$: Actions,
    private _store: Store
  ) {}
}
