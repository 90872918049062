import { uniqBy } from 'lodash-es';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AgentProjectTransactionModel,
  PersonalParticularModel,
  ProjectTeamMemberModel,
  ProjectTransactionRelatedAgentModel,
  SplitMatrixModel,
  SplitPartModel,
} from '@shared/data-access/models';
import { AgentProjectTransactionAction } from '../actions';
import { IProjectTransactionRelatedAgent } from '@shared/data-access/interfaces';
import { round } from 'lodash';

export const featureName = 'agentProjectTransaction';

export interface AgentProjectTransactionState {
  item: AgentProjectTransactionModel | null;
  cobrokeLeaders: ProjectTransactionRelatedAgentModel[];
  mainLeaders: ProjectTransactionRelatedAgentModel[];
  combinedLeaders: ProjectTransactionRelatedAgentModel[];
  agreementDate: Date | null;
}

export const initialState: AgentProjectTransactionState = {
  item: null,
  cobrokeLeaders: [],
  mainLeaders: [],
  combinedLeaders: [],
  agreementDate: null,
};

const splitMatrixDefault = (salesperson: PersonalParticularModel) => {
  const splitMatrixDefault = SplitMatrixModel.createEmpty();
  let { tiers, parties } = splitMatrixDefault;
  tiers = tiers.map((tier, index) => {
    if (index === 0) {
      tier = SplitPartModel.merge(tier, { salespersonId: salesperson.id, salesperson, percentage: 100 });
    } else {
      tier = SplitPartModel.merge(tier, { percentage: 0 });
    }
    return tier;
  });
  parties = parties.map(party => SplitPartModel.merge(party, { percentage: 0 }));
  return SplitMatrixModel.merge(splitMatrixDefault, { tiers, parties });
};

const mapTeamMembersToLeaders = (teamMembers: ProjectTeamMemberModel[]) => {
  return teamMembers.map(member =>
    ProjectTransactionRelatedAgentModel.fromJson<ProjectTransactionRelatedAgentModel, Partial<IProjectTransactionRelatedAgent>>({
      agent: member.salesperson,
      agentId: member.salespersonId,
      teamId: member.teamId,
      tiers: member.salesperson.commissionScheme?.splitMatrices?.[0]?.tiers ?? splitMatrixDefault(member.salesperson).tiers,
      parties: member.salesperson.commissionScheme?.splitMatrices?.[0]?.parties ?? splitMatrixDefault(member.salesperson).parties,
      receivedPercent: 0,
    })
  );
};

const splitCommission = (leaders: ProjectTransactionRelatedAgentModel[]) => {
  let currentPercent = 0;
  return leaders.map((leader, index) => {
    let receivedPercent = round(100 / leaders.length, 4);
    if (index === leaders.length - 1) {
      receivedPercent = 100 - currentPercent;
    }
    currentPercent += receivedPercent;
    return ProjectTransactionRelatedAgentModel.merge(leader, { receivedPercent });
  });
};

export const AgentprojectTransactionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(AgentProjectTransactionAction.loadItemSuccess, (state, prop: { item: AgentProjectTransactionModel }) => ({ ...state, item: prop.item })),
    on(AgentProjectTransactionAction.setAgreementDate, (state, prop: { date: Date }) => ({ ...state, agreementDate: prop.date })),

    // on(AgentProjectTransactionAction.setMainLeadersFromTeamMembers, (state, { teamMembers }) => {
    //   const { cobrokeLeaders } = state;
    //   const mainLeaders = mapTeamMembersToLeaders(teamMembers);
    //   const combinedLeaders = splitCommission(uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId'));
    //   return { ...state, mainLeaders, combinedLeaders };
    // }),
    // on(AgentProjectTransactionAction.setMainLeaders, (state, { leaders }) => {
    //   const { cobrokeLeaders } = state;
    //   const mainLeaders = leaders;
    //   const combinedLeaders = uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId');
    //   return { ...state, mainLeaders, combinedLeaders };
    // }),
    // on(AgentProjectTransactionAction.clearMainLeaders, state => {
    //   const { cobrokeLeaders } = state;
    //   const combinedLeaders = splitCommission(cobrokeLeaders);
    //   return { ...state, mainLeaders: [], combinedLeaders };
    // }),
    // on(AgentProjectTransactionAction.addCobrokeLeadersFromTeamMembers, (state, { teamMembers }) => {
    //   const { mainLeaders } = state;
    //   const cobrokeLeaders = uniqBy([...state.cobrokeLeaders, ...mapTeamMembersToLeaders(teamMembers)], 'agentId');
    //   const combinedLeaders = splitCommission(uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId'));
    //   return { ...state, cobrokeLeaders, combinedLeaders };
    // }),
    // on(AgentProjectTransactionAction.setCobrokeLeaders, (state, { leaders }) => {
    //   const { mainLeaders } = state;
    //   const cobrokeLeaders = leaders;
    //   const combinedLeaders = uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId');
    //   return { ...state, cobrokeLeaders, combinedLeaders };
    // }),
    // on(AgentProjectTransactionAction.removeCobrokeLeaders, (state, { leaders }) => {
    //   const { mainLeaders } = state;
    //   const cobrokeLeaders = state.cobrokeLeaders.filter(x => !leaders.find(y => y.agentId === x.agentId));
    //   const combinedLeaders = splitCommission(uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId'));
    //   return { ...state, cobrokeLeaders, combinedLeaders };
    // }),
    // on(AgentProjectTransactionAction.clearCobrokerLeaders, state => {
    //   const { mainLeaders } = state;
    //   const combinedLeaders = splitCommission(mainLeaders);
    //   return { ...state, cobrokeLeaders: [], combinedLeaders };
    // })
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = AgentprojectTransactionFeature;
