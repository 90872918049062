import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EcddChecklistModel, EcddCustomerModel, EcddModel, EcddOptionGroupModel } from '@shared/data-access/models';

import { EcddAction } from '../actions';
import { EcddSelector } from '../selectors';
import { EEcddType } from '@shared/data-access/enums';

@Injectable({
  providedIn: 'root',
})
export class EcddFacade {
  selectedItem$ = this._store.select(EcddSelector.selectItemDetail);
  selectedDraftItem$ = this._store.select(EcddSelector.selectDraftItem);
  selectedDraftType$ = this._store.select(EcddSelector.selectDraftType);
  selectedDraftState$ = this._store.select(EcddSelector.selectDraftState);
  selectedSubmittingStatus$ = this._store.select(EcddSelector.selectSubmittingStatus);
  selectedSubmittingLoading$ = this._store.select(EcddSelector.selectSubmittingLoading);
  selectedEcddType$ = this._store.select(EcddSelector.selectEcddType);
  selectedSignatureData$ = this._store.select(EcddSelector.selectCustomerSignatureData);
  selectedIsCustomerSigned$ = this._store.select(EcddSelector.selectIsCustomerSigned);
  selectedIsPublic$ = this._store.select(EcddSelector.selectIsPublic);
  selectedCustomer$ = this._store.select(EcddSelector.selectCustomer);
  selectedOptionGroups$ = this._store.select(EcddSelector.selectOptionGroups);
  selectedOptionIdsPrerequisite$ = this._store.select(EcddSelector.selectOptionIdsPrerequisite);
  selectedPrerequisiteIdsActive$ = this._store.select(EcddSelector.selectPrerequisiteIdsActive);
  selectedOptionAppearIds$ = this._store.select(EcddSelector.selectOptionAppearIds);

  constructor(private _store: Store) {}

  setItemDetail(item: EcddModel | null) {
    this._store.dispatch(EcddAction.setItemDetail({ item }));
  }

  loadItemDetail(id: number) {
    this._store.dispatch(EcddAction.loadItemDetail({ id }));
  }

  updateItemDetail(data: Partial<EcddModel>) {
    this._store.dispatch(EcddAction.updateItemDetail({ data }));
  }

  setDraftType(draftType: 'create' | 'edit') {
    this._store.dispatch(EcddAction.setDraftType({ draftType }));
  }

  setDraftItem(item: EcddModel) {
    this._store.dispatch(EcddAction.setDraftItem({ item }));
  }

  updateDraftItem(data: Partial<EcddModel>) {
    this._store.dispatch(EcddAction.updateDraftItem({ data }));
  }

  saveDraftItem() {
    this._store.dispatch(EcddAction.saveDraftItem());
  }

  saveAndSubmitDraftItem() {
    this._store.dispatch(EcddAction.saveAndSubmitDraftItem());
  }

  submitItemSuccess(res: any) {
    this._store.dispatch(EcddAction.submitItemSuccess({ res }));
  }

  submitItemFail(error: any) {
    this._store.dispatch(EcddAction.submitItemFail({ error }));
  }

  resetDraftItem() {
    this._store.dispatch(EcddAction.resetDraftItem());
  }

  resetSubmittingStatus() {
    this._store.dispatch(EcddAction.resetSubmittingStatus());
  }

  setEcddType(ecddType: EEcddType) {
    this._store.dispatch(EcddAction.setEcddType({ ecddType }));
  }

  setCustomer(customer: EcddCustomerModel | null) {
    this._store.dispatch(EcddAction.setCustomer({ customer }));
  }

  updateCustomer(data: Partial<EcddCustomerModel>) {
    this._store.dispatch(EcddAction.updateCustomer({ data }));
  }

  initDraftState({
    draftType,
    draftItem,
    ecddType,
    isPublic = false,
  }: {
    draftType: 'create' | 'edit';
    draftItem: EcddModel;
    ecddType: EEcddType;
    isPublic?: boolean;
  }) {
    this._store.dispatch(
      EcddAction.initDraftState({
        draftType,
        draftItem,
        ecddType,
        isPublic,
      })
    );
  }

  setCustomerSignatureData(data: any) {
    this._store.dispatch(EcddAction.setCustomerSignatureData({ data }));
  }

  resetState() {
    this._store.dispatch(EcddAction.resetState());
  }

  saveAndCreateUrlForCustomer() {
    this._store.dispatch(EcddAction.saveAndCreateUrlForCustomer());
  }

  saveAndProceedToChecklist() {
    this._store.dispatch(EcddAction.saveAndProceedToChecklist());
  }

  setOptionGroupsFromChecklist(checklist: EcddChecklistModel) {
    this._store.dispatch(EcddAction.setOptionGroupsFromChecklist({ checklist }));
  }

  setOptionGroups(optionGroups: EcddOptionGroupModel[]) {
    this._store.dispatch(EcddAction.setOptionGroups({ optionGroups }));
  }

  setResSignatureData(data: any) {
    this._store.dispatch(EcddAction.setResSignatureData({ data }));
  }

  savePublicDraftItem() {
    this._store.dispatch(EcddAction.savePublicDraftItem());
  }

  selectIsSomePrerequisiteIdsActive(ids: number[]) {
    return this._store.select(EcddSelector.selectIsSomePrerequisiteIdsActive(ids));
  }

  selectIsSomeAppearIdsActive(ids: number[]) {
    return this._store.select(EcddSelector.selectIsSomeAppearIdsActive(ids));
  }

  setPrerequisiteIdsActive(prerequisiteIdsActive: number[]) {
    this._store.dispatch(EcddAction.setPrerequisiteIdsActive({ prerequisiteIdsActive }));
  }

  setAppearIdsActive(appearIdsActive: number[]) {
    this._store.dispatch(EcddAction.setAppearIdsActive({ appearIdsActive }));
  }
}
