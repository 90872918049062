import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DummyComponent } from "./dummy.component";
import { DummyRouting } from "./dummy.routing";

@NgModule({
    declarations: [
        DummyComponent
    ],
    imports: [
        CommonModule,
        DummyRouting
    ],
    exports: [
        DummyComponent
    ]
})
export class DummyModule {
}
